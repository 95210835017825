import React, { FC } from "react";
import CompaniesTable, { CompaniesFilter } from "../../components/Companies";
import { useTableList } from "../../hooks/useTableList";
import { getLicenseList } from "../../api/license";
import { INITIAL_STATE } from "./InitialStateList";
import { IUseTableListReturn } from "../../hooks/interfaces/IHookTable";
import { IFiltersListCompanies } from "../../interfaces/IFilterList";
import { ICompaniesList } from "../../interfaces/ICompanies";
export const CompaniesPage: FC = () => {
  const companies: IUseTableListReturn<ICompaniesList, IFiltersListCompanies> =
    useTableList<ICompaniesList, IFiltersListCompanies>(
      getLicenseList,
      INITIAL_STATE,
      "tenantKey"
    );

  const {
    list,
    flat,
    pagination,
    count,
    direction,
    sort,
    setFilters,
    setPagination,
    setDirection,
    setSort,
    setSizePerPage,
    updateStatusCompanies,
  } = companies;

  return (
    <div className="companies-container">
      <h2 className="header-title">
        <span>
          {" "}
          <span className="mark-brand">C</span>ompanies
        </span>
        <span className="underline"></span>
      </h2>
      <div className="tools-table">
        <h3>Filters</h3>
        <CompaniesFilter filters={setFilters} />
      </div>
      <div className="table-content">
        <CompaniesTable
          list={list}
          flat={flat}
          direction={direction}
          sort={sort}
          pagination={setPagination}
          setDirection={setDirection}
          setSort={setSort}
          page={pagination.page}
          pageSize={pagination.pageSize}
          count={count}
          updateStatusCompanies={updateStatusCompanies}
          setSizePerPage={setSizePerPage}
        />
      </div>
    </div>
  );
};
