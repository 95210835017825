import React, { FC, useState, useContext } from "react";
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { IoIosContact, IoIosAddCircle, IoIosBusiness, IoIosCloud, IoIosExit, IoIosDownload } from "react-icons/io";
import { IHookAppReturn } from "src/hooks/interfaces/IHookAppState";

export const Header: FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(true);

  const { logout, user } = useContext<IHookAppReturn>(AppContext);

  const toggleNavbar: FuncVoidWithoutParams = () => setCollapsed(!collapsed);

  const location: any = useLocation();

  return (
    <>
      <header>
        <Navbar className="nav-header navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
          <Container>
            <NavbarBrand tag={Link} to="/" className="brand"><span className="mark-brand">P</span>ryvicy</NavbarBrand>
            <NavbarToggler onClick={toggleNavbar} className="mr-2" />
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
              <ul className="navbar-nav flex-grow">
                <NavItem>
                <Link to="/PryvicyMsiChanger.zip" target="_blank" download>
                  <button className="button-filter mt-1 mr-2">
                  <IoIosDownload className="icon-header text-white" />
                    MSI Changer
                  </button>
                </Link>
                </NavItem>                         
                <NavItem
                  active={location.pathname === "/user-info"
                    || location.pathname === "/user-info/details"
                    || location.pathname === "/user-info/edit"}>
                  <NavLink tag={Link} className="text-dark" to="/user-info">
                    <IoIosContact className="icon-header" />
                    <span className="title-header">User Info</span>
                  </NavLink>
                </NavItem>
                {user && user.role === "superadmin" ?
                  <>
                    <NavItem active={location.pathname === "/new-user"}>
                      <NavLink tag={Link} className="text-dark" to="/new-user">
                        <IoIosAddCircle className="icon-header" />
                        <span className="title-header">Add New User</span>
                      </NavLink>
                    </NavItem>
                    <NavItem active={location.pathname === "/licenses"}>
                      <NavLink tag={Link} className="text-dark" to="/licenses">
                        <IoIosBusiness className="icon-header" />
                        <span className="title-header">Companies</span>
                      </NavLink>
                    </NavItem>
                  </>
                  : null}
                <NavItem active={location.pathname === "/operations"}>
                  <NavLink tag={Link} className="text-dark" to="/operations">
                    <IoIosCloud className="icon-header" />
                    <span className="title-header">Operations</span>
                  </NavLink>
                </NavItem>
                <NavItem >
                  <NavLink tag={"a"} className="text-dark" style={{ cursor: "pointer" }} onClick={logout}>
                    <IoIosExit className="icon-header" />
                    <span className="title-header">Logout</span>
                  </NavLink>
                </NavItem>
              </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    </>
  );
};