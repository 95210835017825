import React, { FC } from "react";

export const Sorting: FC<any> = ({ children, name, direction, sort, setSort, classTh }) => {
  return (
    <>
      <th
        className={sort === name ? direction === "asc" ?
        `sorting current-sort current-sort-up` : `sorting current-sort current-sort-down` : `sorting`}
        onClick={() => setSort(name)}>
        <div className={`sorting ${classTh}`}>
          <span>{children}</span>
          <span className={direction === "asc" ? "sotring-arrow sorting-arrow-up" : "sotring-arrow sorting-arrow-down"}></span>
        </div>
      </th>
    </>
  );
};