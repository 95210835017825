import React, { FC, useContext, useEffect } from "react";
import { TabPane, TabContent } from "reactstrap";
import { AddUserForm } from "./AddUserForm";
import { AddTenantForm } from "./AddTenantForm";
import { IUser } from "../../interfaces/IUser";
import { ITenant } from "../../interfaces/ITenant";
import { Formik, Form, FormikHelpers } from "formik";
import { validationSchema } from "../../validation/addUserValidation";
import { toast } from "react-toastify";
import { history } from "../../utils/history";
import { IObjectNormalize } from "../../interfaces/IObjectNormalize";
import { AppContext } from "../../context/AppContext";
import { IHookAppReturn } from "../../hooks/interfaces/IHookAppState";
import { OUTLOOK } from "../../constants/attachmentsTransport";

const INITIAL_STATE_USER_UPDATE: Partial<IUser> = {
  id: undefined,
  gender: "Male",
  location: "",
  country: "",
  occupation: "",
  address: "",
  website: "",
  mobile: "",
  position: "",
  company: "",
  surname: "",
  name: "",
};

const INITIAL_STATE_USER: IUser & { password?: string; } = {
  id: undefined,
  gender: "Male",
  location: "",
  country: "",
  occupation: "",
  address: "",
  website: "",
  mobile: "",
  position: "",
  company: "",
  surname: "",
  name: "",
  email: "",
  password: "",
};

const INITIAL_STATE_TENANT: ITenant = {
  tenantKey: "",
  useExtensionFilter: true,
  extensionFilterList: [],
  usePasswordEmailTemplate: true,
  passwordEmailSubjectTemplate: "",
  passwordEmailTemplate: "",
  passwordEmailTemplateFormat: "text/html",
  passwordEmailSendFromSpecialAddress: false,
  auditFor: "all",
  emailEncryptionMode: "NotEncrypt",
  useBccEmail: true,
  bccEmailAddresses: [],
  sftpSettings: {
    emailTemplateSettings: {
      useEmailTemplate: true,
      emailSubjectTemplate: "",
      emailTemplate: "",
      emailTemplateFormat: "text/html",
    },
    serverSettings: {
      host: "",
      port: "",
      username: "",
      password: "",
      directory: "",
    }
  },
  attachmentsArchivingMode: "Archive",
  attachmentsDeliveryTransportType: OUTLOOK,
  archiveName:"archive.zip",
  useDomainsWhiteList: false,
  domainsWhiteList: [],
};

type IUserForm = IUser & ITenant & { confirmPassword: string; extesionField: string; };

interface IProps {
  activeTab: string;
  toggleTab: (value: string) => void;
  upsert?: any;
  user?: any;
  tenant?: any;
  editable?: boolean;
  validation?: any;
  isCurrentUser?: boolean;
  error?: any;
  submitting?: boolean;
  complete?: boolean;
}

export const AddUser: FC<IProps> =
  ({ activeTab, toggleTab, user, editable, validation, isCurrentUser, tenant, upsert, error, complete, submitting }) => {

    const INITIAL_STATE: IUserForm = {
      ...INITIAL_STATE_USER,
      confirmPassword: "",
      ...INITIAL_STATE_TENANT,
      extesionField: "",
    };

    const { writeUser }: any = useContext<IHookAppReturn>(AppContext);

    const initialValues: any = user ? { ...INITIAL_STATE, ...user, ...tenant } : INITIAL_STATE;

    const submit: IFormikSubmit<any> = async (values: any, actions: FormikHelpers<any>) => {
      function Normalizer<T1>(filter: T1): IObjectNormalize[] {
        return Object.keys(filter).map((elem: string): IObjectNormalize => {
          return {
            key: elem,
            value: values[elem],
          };
        });
      }

      // tslint:disable-next-line: typedef
      const ReducerObject = (obj: any, item: IObjectNormalize) => Object.assign(obj, { [item.key]: item.value });

      const userForm: any = Normalizer<any>(editable ? INITIAL_STATE_USER_UPDATE : INITIAL_STATE_USER).reduce(
        ReducerObject, {});
      const tenantForm: ITenant = Normalizer<ITenant>(INITIAL_STATE_TENANT).reduce(
        ReducerObject, {});

      console.log(userForm);
      console.log(tenantForm);

      await upsert(userForm, tenantForm);

    };

    useEffect(() => {
      if (error) {
        toast.error(error.data.errorMessage);
        return;
      }

      if (!submitting && complete) {
        toast.success(user && user.id ? "User updated" : "User created");

        if (isCurrentUser) {
          writeUser(user);
          history.push("/user-info");
        } else {
          history.push("/licenses");
        }
      }
    }, [error, submitting, complete, user, isCurrentUser, writeUser]);

    const toggleBack: FuncVoidWithoutParams = () => {
      toggleTab("1");
    };

    const toggleNext: FuncVoidWithoutParams = () => {
      toggleTab("2");
    };
    return (
      <>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validation || validationSchema}
        >
          {(formProps) => (
            <Form>
              <div className="block-form">
                <div className="row">
                  <div className="col-12 form-block">
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <AddUserForm {...formProps} editable={editable} />
                      </TabPane>
                      <TabPane tabId="2">
                        <AddTenantForm {...formProps} editable={editable} />
                      </TabPane>
                    </TabContent>
                    <div className="row actions">
                      <div className="col-6">
                        <div className="row">
                          <div className="col">
                            <button className="buttons-pagination" disabled={activeTab === "1"} onClick={toggleBack}>Back</button>
                            <button className="buttons-pagination" disabled={activeTab === "2"} onClick={toggleNext}>Next</button>
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="row">
                          <div className="offset-6 col-6">
                            {
                              editable ? (
                                <button
                                  className="button-submit"
                                  type="submit" disabled={!formProps.isValid}>Update user</button>
                              ) : (
                                  <>
                                    <button
                                      className="button-submit"
                                      type="submit" disabled={!formProps.isValid || !formProps.dirty}>Submit</button>
                                  </>
                                )
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  };
