import { IHookTable } from "../interfaces/IHookTable";
import produce from "immer";
import { AnyAction } from "redux";
import {
  GET_LIST_REQUEST,
  SET_FILTERS,
  SET_PAGINATION,
  GET_LIST_REQUEST_DONE,
  SET_DIRECTION,
  SET_SORT,
  SET_COUNT_PER_PAGE,
  UPDATE_STATUS
} from "../constants/useTableListConstants";

export const INITIAL_STATE: IHookTable = {
  pagination: {
    page: 1,
    pageSize: 10,
  },
  sort: "",
  direction: "asc",
  filters: {
    like: {},
    eq: {},
  },
  list: [],
  flat: {},
  count: 0,
  isLoadingList: false,
};

export const reducer: any = <T, F>(state: IHookTable<T, F>, action: AnyAction) => {
  return produce(state, (draft: IHookTable<T, F>) => {
    switch (action.type) {
      case GET_LIST_REQUEST:
        draft.isLoadingList = true;
        break;
      case SET_FILTERS:
        draft.filters = action.payload;
        draft.pagination.page = 1;
        break;
      case SET_PAGINATION:
        draft.pagination.page = action.payload;
        break;
      case SET_DIRECTION:
        draft.direction = action.payload;
        break;
      case SET_SORT:
        draft.sort = action.payload.sort;
        draft.direction = action.payload.direction;
        break;
      case SET_COUNT_PER_PAGE:
        draft.pagination.pageSize = action.payload;
        break;
      case GET_LIST_REQUEST_DONE:
        draft.isLoadingList = false;
        draft.list = action.payload.data;
        draft.flat = action.payload.flatData;
        draft.count = action.payload.total;
        break;
      case UPDATE_STATUS:
        draft.flat[action.payload.tenantKey] = action.payload;
        break;

    }
  });
};