import React, { FC } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import LoginPage from "../LoginPage";
import { AuthRoute } from "../../components/AuthRoute";
import { PrivateRoute } from "../../components/PrivateRoute";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Header } from "../../components/Header";

import UserInfoPage from "../UserInfoPage";
import EditUserPage from "../EditUserPage";
import Layout from "../../components/Layout";
import AddUserPage from "../AddUserPage";
import OperationsPage from "../OperationsPage";
import CompaniesPage from "../CompaniesPage";

import RegisterPage from "../RegisterPage";

export const RootPage: FC = () => {

  return (
    <>
      <Switch>
        <Redirect from="/" exact={true} to="/user-info" />
        <AuthRoute exact={true} path="/login">
          <LoginPage />
        </AuthRoute>
        <AuthRoute exact={true} path="/register">
          <RegisterPage />
        </AuthRoute>
        <Route path="/">
          <Layout>
            <Header />
            <Switch>
              <PrivateRoute path="/user-info">
                <UserInfoPage />
              </PrivateRoute>
              <PrivateRoute path="/new-user">
                <AddUserPage />
              </PrivateRoute>
              <PrivateRoute path="/operations">
                <OperationsPage />
              </PrivateRoute>
              <PrivateRoute path="/licenses/:id">
                <EditUserPage/>
              </PrivateRoute>
              <PrivateRoute path="/licenses">
                <CompaniesPage />
              </PrivateRoute>
            </Switch>
          </Layout>
        </Route>
      </Switch>
      <ToastContainer />
    </>
  );
};