import React, { FC } from "react";
import { Formik, Form, FormikHelpers } from "formik";

interface IProps<I, F> {
  filters: (filters: F) => void;
  initialValues: I;
  formatter: (values: I) => F;
}

type FiltersType<I = any, F = any> = FC<IProps<I, F>>;

export const Filters: FC<any> = (props) => {

  const {
    initialValues,
    filters,
    children,
    formatter,
  } = props;

  const onSubmit: IFormikSubmit<any> = (values: any, actions: FormikHelpers<any>) => {
    let f: any = formatter(values);
    filters(f);
  };

  const onClear: IFormikSubmit<any> = (values: any, actions: FormikHelpers<any>) => {
    let f: any = formatter(null);
    actions.setValues({
      ...initialValues,
    });
    filters(f);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        onReset={onClear}
      >
        {(formProps) => (
          <Form>
            {children(formProps)}
            <div className="row">
              <div className="col-3">
                <button className="button-filter" type="submit">Find</button>
                <button type="reset" className="button-filter-clear ml-2">Clear filters</button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};