import React, { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { Location } from "history";

export const UserLayout: FC = ({ children }) => {
  const { pathname }: Location = useLocation();

  return (
    <>
      <div className="header-user-info">
        <h2 className="header-title">
          <span> <span className="mark-brand">U</span>ser info</span>
          <span className="underline"></span>
        </h2>
        <button className="header-button-edit">
          {
            pathname === "/user-info/details" ? (
              <Link to="/user-info/edit">Update</Link>
            ) : (
              <Link to="/user-info/details">Back to details</Link>
            )
          }
        </button>
      </div>
      <div className="body-user-info">
        {children}
      </div>
    </>
  );
};