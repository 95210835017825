import React, { FC } from "react";
import { FormikErrors, FormikTouched } from "formik";

interface IProps {
  name: string;
  errors: FormikErrors<any>;
  touched: FormikTouched<any>;
}
export const FormGroupInputError: FC<IProps> = (props) => {
  const {
    name,
    errors,
    touched,
  } = props;

  return (
    <>
      {errors[name] && touched[name] ? (
        <div className="error-field">{errors[name]}</div>
      ) : null}
    </>
  );
};