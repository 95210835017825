import React, { FC } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
export const CompaniesTd: FC<any> = ({ item, updateStatusCompanies, flatItem }) => {

  // tslint:disable-next-line: typedef
  const setEnable = async (tenantKey: string, isEnabled: boolean) => {
    await updateStatusCompanies(tenantKey, isEnabled);
    isEnabled ? toast.success("Company enabled") : toast.error("Company disabled");
  };

  return (
    <>
      <tr>
        <td>{item.tenantKey}</td>
        <td>{item.tenant}</td>
        <td>{item.issuedAt}</td>
        <td>{item.lastChangedAt}</td>
        <td>{item.validThru || "unlimited"}</td>
        <td style={{ color: flatItem.isEnabled ? "var(--green)" : "var(--red)" }}>{!flatItem.isEnabled ? "disabled" : "enabled"}</td>
        <td>
          <button
            style={{ width: 100 }}
            onClick={() => setEnable(item.tenantKey, !flatItem.isEnabled)}
            className={`btn ${!flatItem.isEnabled ? "btn-success" : "btn-danger"}`}>{flatItem.isEnabled ? "Disable" : "Enable"}</button>
        </td>
        <td>
          <button className="button-edit">
            <Link to={`/licenses/${item.tenantKey}`}>
              Edit
            </Link>
          </button>
        </td>
      </tr>
    </>
  );
};