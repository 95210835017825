import { IHookTable } from "../../hooks/interfaces/IHookTable";
import { IFiltersListOperations } from "../../interfaces/IFilterList";
import { IOperationsList } from "../../interfaces/IOperations";


export const INITIAL_STATE: IHookTable<IOperationsList, IFiltersListOperations> = {
  pagination: {
    page: 1,
    pageSize: 10,
  },
  sort: "createdAt",
  direction: "desc",
  filters: {
    like: {},
    eq: {},
  },
  list: [],
  count: 0,
  isLoadingList: false,
};