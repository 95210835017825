import { AnyAction } from "redux";
import { useReducer, useEffect, useCallback, Reducer } from "react";
import { getAuthToken, setAuthToken, removeAuthToken } from "../utils/localStorage";
import { signIn, getCurrentUser } from "../api";
import { IUserExtends } from "../interfaces/IUser";
import { reducer, INITIAL_STATE } from "./reducers/useAppReducer";
import {
  writeTokenAction,
  writeUserAction,
  finishLoadAction,
  getUserActionRequestFailed,
  getUserActionRequest,
  signInActionRequest,
  signInActionRequestFailed,
  logoutAction,
  startAppAction
} from "./actions/useAppActions";
import { IResponseFormatToken } from "../interfaces/IResponseFormatToken";
import { IHookAppReturn, IHookAppState } from "./interfaces/IHookAppState";

export const useApp: FuncWithoutParams<IHookAppReturn> =
  () => {
    const [state, dispatch] = useReducer<Reducer<IHookAppState, AnyAction>>(reducer, INITIAL_STATE);

    const logout: FuncVoidWithoutParams = useCallback(() => {
      removeAuthToken();
      dispatch(logoutAction());
    }, []);

    const writeToken: FuncVoid<string> = (token: string) => {
      dispatch(writeTokenAction(token));
    };

    const writeUser: FuncVoid<IUserExtends> = (user: IUserExtends) => {
      dispatch(writeUserAction(user));
    };

    const finishLoad: FuncVoidWithoutParams = () => {
      setTimeout(() => {
        dispatch(finishLoadAction());
      }, 1000);
    };

    const getUser: FuncVoidWithoutParams = useCallback(async () => {
      dispatch(getUserActionRequest());
      try {
        const result: IUserExtends = await getCurrentUser();
        writeUser(result);
      } catch (e) {
        logout();
        dispatch(getUserActionRequestFailed(e.data.errorMessage));
        finishLoad();
      }
    }, [logout]);


    const login: IFuncVoidLogin = useCallback(async (email: string, password: string) => {
      dispatch(signInActionRequest());
      try {
        const result: IResponseFormatToken = await signIn(email, password);
        setAuthToken(result.token);
        writeToken(result.token);
        await getUser();
        finishLoad();
      } catch (e) {
        logout();
        dispatch(signInActionRequestFailed(e.data.errorMessage));
        finishLoad();
      }
    }, [getUser, logout]);

    const startApp: FuncPromise<string, void> = useCallback(async (token: string) => {
      dispatch(startAppAction());
      writeToken(token);
      if (token) {
        await getUser();
      }

      finishLoad();
    }, [getUser]);

    useEffect(() => {
      const tokenStorage: string | null = getAuthToken();
      if (tokenStorage) {
        startApp(tokenStorage);
      } else {
        finishLoad();
      }
    }, [startApp]);

    const {
      token,
      user,
      isLoadingUser,
      isLoadingToken,
      error,
    } = state;
    return {
      token,
      user,
      writeToken,
      writeUser,
      login,
      isLoading: isLoadingToken && isLoadingUser,
      isLoadingToken,
      isLoadingUser,
      getUser,
      finishLoad,
      logout,
      error,
    };
  };