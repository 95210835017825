import { AnyAction } from "redux";
import produce from "immer";
import {
  GET_USER_REQUEST,
  GET_TENANT_REQUEST,
  GET_USER_REQUEST_DONE,
  GET_TENANT_REQUEST_DONE,
  FINISH_LOAD,
  UPSERT_USER_REQUEST,
  UPSERT_TENANT_REQUEST,
  UPSERT_USER_REQUEST_DONE,
  UPSERT_TENANT_REQUEST_DONE,
  UPSERT_TENANT_REQUEST_FAILED,
  UPSERT_USER_REQUEST_FAILED
} from "../constants/useUpsertUserConstants";

interface IHookUpsertUser {
  user: any;
  tenant: any;
  isUserLoading: boolean;
  isTenantLoading: boolean;
  error: any;
  submitting: boolean;
  complete: boolean;
}

export const INITIAL_STATE: IHookUpsertUser = {
  user: undefined,
  tenant: undefined,
  isUserLoading: false,
  isTenantLoading: false,
  error: null,
  submitting: false,
  complete: false,
};

// tslint:disable-next-line: typedef
export const reducer = (state: IHookUpsertUser, action: AnyAction) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case GET_USER_REQUEST:
        draft.isUserLoading = true;
        break;
      case GET_TENANT_REQUEST:
        draft.isTenantLoading = true;
        break;
      case GET_USER_REQUEST_DONE:
        draft.user = action.payload;
        break;
      case GET_TENANT_REQUEST_DONE:
        draft.tenant = action.payload;
        break;
      case UPSERT_USER_REQUEST:
        draft.isUserLoading = true;
        draft.submitting = true;
        draft.error = null;
        break;
      case UPSERT_TENANT_REQUEST:
        draft.isTenantLoading = true;
        break;
      case UPSERT_USER_REQUEST_DONE:
        draft.isUserLoading = false;
        draft.user = action.payload;
        break;
      case UPSERT_TENANT_REQUEST_DONE:
        draft.isTenantLoading = false;
        draft.submitting = false;
        draft.complete = true;
        draft.tenant = action.payload;
        break;
      case UPSERT_USER_REQUEST_FAILED:
        draft.isUserLoading = false;
        draft.submitting = false;
        draft.complete = false;
        draft.error = action.payload;
        break;
      case UPSERT_TENANT_REQUEST_FAILED:
        draft.isTenantLoading = false;
        draft.submitting = false;
        draft.complete = false;
        draft.error = action.payload;
        break;
      case FINISH_LOAD:
        draft.isTenantLoading = false;
        draft.isUserLoading = false;
        break;
    }
  });
};