import { post } from "./request";
import { IResponseFormatToken } from "src/interfaces/IResponseFormatToken";
import { IUserExtends, IUser } from "src/interfaces/IUser";

const BASE: string = "/account";

export const signIn: IFuncPromiseLogin<IResponseFormatToken> =
  (email: string, password: string): Promise<IResponseFormatToken> => (
    post(`${BASE}/sign-in`, {
      email,
      password,
    })
  );

export const refreshToken: FuncPromise<string, IResponseFormatToken> =
  (id: string): Promise<IResponseFormatToken> => (
    post(`${BASE}/refresh-token/${id}`)
  );

export const signUp: FuncPromise<IUser, IUserExtends> =
  (data: IUser): Promise<IUserExtends> => (
    post(`${BASE}/sign-up`, data)
  );