import {USER_TOKEN_STORAGE_KEY} from "../constants/storage";

export function getAuthToken(): string | null {
  const token: string | null = localStorage.getItem(USER_TOKEN_STORAGE_KEY);
  return token;
}

export function setAuthToken (token: string): void {
  localStorage.setItem(USER_TOKEN_STORAGE_KEY, token);
}

export function removeAuthToken(): void {
  localStorage.removeItem(USER_TOKEN_STORAGE_KEY);
}