import React, { FC } from "react";
import { ConnectedRouter } from "connected-react-router";
import { history } from "./utils/history";
import store from "./store";
import "./App.css";
import { Provider } from "react-redux";
import RootPage from "./pages/RootPage";
import "bootstrap/dist/css/bootstrap.min.css";
import { useApp } from "./hooks/useApp";
import { AppContext } from "./context/AppContext";
import { IHookAppReturn } from "./hooks/interfaces/IHookAppState";
import { SpinnerLoader } from "./components/Spinner";


const App: FC = () => {

  const AppHook: IHookAppReturn = useApp();

  if (AppHook.isLoading) {
    return <SpinnerLoader />;
  }

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <AppContext.Provider value={AppHook}>
          <RootPage />
        </AppContext.Provider>
      </ConnectedRouter>
    </Provider>
  );
};

export default App;