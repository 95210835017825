import React, { FC } from "react";
import { Field } from "formik";
import { RadioRaw } from "./RadioRaw";

export const UIRadio: FC<any> = ({ value, name, title }) => {
  return (
    <>
      <Field
        name={name}
        title={title}
        component={RadioRaw}
        value={value}
      />
    </>
  );
};