import React, { FC } from "react";

interface IProps {
  field: any;
  id: string;
  title: string;
}
export const CheckboxRaw: FC<IProps> = ({field, id, title}) => (
  <>
    <input
      type="checkbox"
      id={id}
      className="checkbox"
      checked={field.value}
      {...field}
    />
    <label htmlFor={id}>{title}</label>
  </>
);
