import {
  GET_LIST_REQUEST,
  GET_LIST_REQUEST_DONE,
  GET_LIST_REQUEST_FAILED,
  SET_COUNT_PER_PAGE,
  SET_SORT,
  SET_DIRECTION,
  SET_PAGINATION,
  SET_FILTERS,
  UPDATE_STATUS
} from "../constants/useTableListConstants";
import { AnyAction } from "redux";


export const getListRequest: FuncWithoutParams<AnyAction>  = () => ({
  type: GET_LIST_REQUEST,
});

export const getListRequestDone: Func<any, AnyAction> = (res: any) => ({
  type: GET_LIST_REQUEST_DONE,
  payload: res
});

export const getListRequestFailed: Func<any, AnyAction> = (e: any) => ({
  type: GET_LIST_REQUEST_FAILED,
  payload: e,
});

export const setFiltersRequest: Func<any, AnyAction> = (filters: any) => ({
  type: SET_FILTERS,
  payload: filters,
});

export const updateStatus: Func<any, AnyAction> = (item: any) => ({
  type: UPDATE_STATUS,
  payload: item,
});

export const setSortRequest: Func<any, AnyAction> = (sort: any) => ({
  type: SET_SORT,
  payload: sort,
});

export const setPaginationRequest: Func<number, AnyAction> = (page: number) => ({
  type: SET_PAGINATION,
  payload: page,
});

export const setDirectionRequest: Func<string, AnyAction> = (direction: string) => ({
  type: SET_DIRECTION,
  payload: direction,
});

export const setSizePerPageRequest: Func<number, AnyAction> = (pageSize: number) => ({
  type: SET_COUNT_PER_PAGE,
  payload: pageSize,
});
