import React, { FC, useEffect } from "react";
import { FormGroup, Label } from "reactstrap";
import { Field } from "formik";
import { IoIosClose } from "react-icons/io";
import { toast } from "react-toastify";

import { UICheckbox } from "../UI";
import { UIRadio } from "../UI/Radio";
import FormGroupInput from "../UI/FormGroupInput";
import {
  TRANSPORT_LABELS,
  SFTP,
  OUTLOOK,
} from "../../constants/attachmentsTransport";
import { useSftp } from "../../hooks/useSftp";

export const AddTenantForm: FC<any> = (props) => {
  const { errors, touched, values, setFieldValue } = props;
  const extensionFilterList = values.extensionFilterList || [];
  const domainsWhiteList = values.domainsWhiteList || [];
  const bccEmailAddresses = values.bccEmailAddresses || [];
  const { serverSettings = {} } = values.sftpSettings || {};
  const checkButtonIsDisabled = !serverSettings.host;

  const { submitting, complete, error, sftp, checkSftp } = useSftp();

  useEffect(() => {
    if (error) {
      toast.error(error.data.errorMessage || "Sftp settings invalid");
      return;
    }

    if (!submitting && complete) {
      if (sftp.isAvailable) {
        toast.success("Sftp settings are valid");
      } else {
        toast.error("Sftp settings invalid: " + sftp.errorMessage);
      }
    }
  }, [submitting, complete, error, sftp]);

  const existTag = (tag: string) => {
    let exist: boolean = true;
    const tagFixed: string =
      "." + tag.toLowerCase().trimLeft().replace(/^\.+/g, "");
    values.extensionFilterList.forEach((elem: string) => {
      if (elem === tagFixed) {
        exist = false;
      }
    });

    return exist;
  };

  const existDomainsTag = (tag: string) => {
    let exist: boolean = true;
    const tagFixed: string = tag.toLowerCase().trimLeft().replace("@", "");
    values.domainsWhiteList.forEach((elem: string) => {
      if (elem === tagFixed) {
        exist = false;
      }
    });

    return exist;
  };

  const addTag: FuncVoidWithoutParams = () => {
    if (values.extesionField && existTag(values.extesionField)) {
      const newState: string[] = [...extensionFilterList, values.extesionField];
      props.setValues({
        ...values,
        extensionFilterList: newState,
      });
      setFieldValue("extesionField", "");
    }
  };

  const addDomainsTag: FuncVoidWithoutParams = () => {
    if (values.domainsField && existDomainsTag(values.domainsField)) {
      const newState: string[] = [...domainsWhiteList, values.domainsField];
      props.setValues({
        ...values,
        domainsWhiteList: newState,
      });
      setFieldValue("domainsField", "");
    }
  };

  const removeTag: FuncVoid<number> = (index: number) => {
    const newExtensionFilterList = extensionFilterList.filter(
      (email: string, i: number) => i !== index
    );
    setFieldValue("extensionFilterList", newExtensionFilterList);
  };

  const removeDomainsTag: FuncVoid<number> = (index: number) => {
    const newDomainsWhiteList = domainsWhiteList.filter(
      (email: string, i: number) => i !== index
    );
    setFieldValue("domainsWhiteList", newDomainsWhiteList);
  };

  const existEmail = (email: string) =>
    bccEmailAddresses.some((bccEmail: string) => bccEmail === email);

  const addEmail: FuncVoidWithoutParams = () => {
    if (
      !values.bccEmailField ||
      existEmail(values.bccEmailField) ||
      errors.bccEmailField
    ) {
      return;
    }
    const newBccEmailAddresses: string[] = [
      ...bccEmailAddresses,
      values.bccEmailField,
    ];
    props.setValues({
      ...values,
      bccEmailAddresses: newBccEmailAddresses,
    });
    setFieldValue("bccEmailField", "");
  };

  const removeEmail: FuncVoid<number> = (removedIndex: number) => {
    const newBccEmailAddresses = bccEmailAddresses.filter(
      (email: string, index: number) => index !== removedIndex
    );
    setFieldValue("bccEmailAddresses", newBccEmailAddresses);
  };

  const checkSftpConnection: FuncVoidWithoutParams = () => {
    checkSftp(serverSettings);
  };

  return (
    <>
      <div className="row">
        <div className="col-3">
          <FormGroup>
            <Label for="extesionField">Use extension filter</Label>
            <div className="checkbox-container-form">
              <UICheckbox
                id="useExtensionFilter"
                name="useExtensionFilter"
                title=""
              />
            </div>
          </FormGroup>
        </div>
      </div>
      <div className="row">
        <div className="col-8">
          {values.useExtensionFilter && (
            <FormGroup className="without-margin">
              <Label for="extesionField">Extension filter list</Label>
              <div className="row">
                <div className="col-5 input-extenstion-list-col">
                  <Field
                    type="text"
                    className="form-control input-extenstion-list"
                    id="extesionField"
                    placeholder="Enter extension"
                    name="extesionField"
                  />
                </div>
                <div className="col-3 button-extension-list-col">
                  <button
                    className="button-extension-list"
                    type="button"
                    onClick={addTag}
                  >
                    Add extension
                  </button>
                </div>
              </div>
            </FormGroup>
          )}
          {!values.useExtensionFilter && values.extensionFilterList.length > 0 && (
            <FormGroup className="without-margin">
              <Label>Extension filter list</Label>
            </FormGroup>
          )}
        </div>
        <div className="col-6 mb-3">
          {values.extensionFilterList.map((tag: string, index: number) => (
            <div
              className={
                !values.useExtensionFilter
                  ? "disabled-tag tag"
                  : "enabled-tag tag"
              }
              key={index}
            >
              <span>.{tag.toLowerCase().trimLeft().replace(/^\.+/g, "")}</span>
              {values.useExtensionFilter && (
                <span onClick={() => removeTag(index)}>
                  <IoIosClose className="close-icon" />
                </span>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="row">
        <div className="col-3">
          <FormGroup>
            <Label for="domainsField">Use domains white list</Label>
            <div className="checkbox-container-form">
              <UICheckbox
                id="useDomainsWhiteList"
                name="useDomainsWhiteList"
                title=""
              />
            </div>
          </FormGroup>
        </div>
      </div>
      <div className="row">
        <div className="col-8">
          {values.useDomainsWhiteList && (
            <FormGroup className="without-margin">
              <Label for="domainsField">Domains white list</Label>
              <div className="row">
                <div className="col-5 input-extenstion-list-col">
                  <Field
                    type="text"
                    className="form-control input-extenstion-list"
                    id="domainsField"
                    placeholder="Enter domain"
                    name="domainsField"
                  />
                </div>
                <div className="col-3 button-extension-list-col">
                  <button
                    className="button-extension-list"
                    type="button"
                    onClick={addDomainsTag}
                  >
                    Add domain
                  </button>
                </div>
              </div>
            </FormGroup>
          )}
          {!values.useDomainsWhiteList && values.domainsWhiteList.length > 0 && (
            <FormGroup className="without-margin">
              <Label>Domains white list</Label>
            </FormGroup>
          )}
        </div>
        <div className="col-6 mb-3">
          {values.domainsWhiteList.map((tag: string, index: number) => (
            <div
              className={
                !values.useDomainsWhiteList
                  ? "disabled-tag tag"
                  : "enabled-tag tag"
              }
              key={index}
            >
              <span>{tag.toLowerCase().trimLeft().replace("@", "")}</span>
              {values.useDomainsWhiteList && (
                <span onClick={() => removeDomainsTag(index)}>
                  <IoIosClose className="close-icon" />
                </span>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <FormGroup>
            <Label for="auditFor">Audit for</Label>
            <Field as="select" name="auditFor" className="form-control">
              <option value="withAttachments">With attachments</option>
              <option value="all">All</option>
            </Field>
          </FormGroup>
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <FormGroup>
            <Label for="emailEncryptionMode">
              Encryption mode for email body:
            </Label>
            <Field
              as="select"
              name="emailEncryptionMode"
              className="form-control"
            >
              <option value="NotEncrypt">Never encrypt</option>
              <option value="AskEncrypt">Ask sender</option>
              <option value="Encrypt">Always encrypt</option>
            </Field>
          </FormGroup>
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <FormGroup>
            <Label for="attachmentsArchivingMode">
              Archiving mode for attachments:
            </Label>
            <Field
              as="select"
              name="attachmentsArchivingMode"
              className="form-control"
            >
              <option value="NotArchive">Never archive</option>
              <option value="AskArchive">Ask sender</option>
              <option value="Archive">Always archive</option>
            </Field>
          </FormGroup>
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <FormGroup>
            <Label for="senderAddress">
              Send email with password using dedicated pryvicy.com email
              account:
            </Label>
            <div className="checkbox-container-form">
              <UICheckbox
                id="passwordEmailSendFromSpecialAddress"
                name="passwordEmailSendFromSpecialAddress"
                title=""
              />
            </div>
          </FormGroup>
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <FormGroupInput
            type="text"
            id="archiveName"
            name="archiveName"
            label="Archive Name"
            markRequired={false}
            placeholder="Archive"
            errors={errors}
            touched={touched}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <FormGroup>
            <Label for="attachmentsDeliveryTransportType">
              Attachments delivery transport
            </Label>
            <div>
              {Object.entries(TRANSPORT_LABELS).map(([transport, label]) => {
                if (transport === "Outlook") {
                  return (
                    <UIRadio
                      value={transport}
                      name="attachmentsDeliveryTransportType"
                      title="Email"
                    />
                  );
                } else if (transport !== "Gmail") {
                  return (
                    <UIRadio
                      value={transport}
                      name="attachmentsDeliveryTransportType"
                      title={label}
                    />
                  );
                }
              })}
            </div>
          </FormGroup>
        </div>
        <div className="col-12">
          <div className="card card-body mb-3">
            {values.attachmentsDeliveryTransportType === SFTP && (
              <>
                <div className="row">
                  <div className="col-3">
                    <FormGroupInput
                      type="text"
                      id="sftpHost"
                      name="sftpSettings.serverSettings.host"
                      label="Host"
                      placeholder="sftp://"
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-3">
                    <FormGroupInput
                      type="text"
                      id="sftpPort"
                      name="sftpSettings.serverSettings.port"
                      label="Port"
                      placeholder=""
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-3">
                    <FormGroupInput
                      type="text"
                      id="sftpUsername"
                      name="sftpSettings.serverSettings.username"
                      label="User"
                      placeholder=""
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-3">
                    <FormGroupInput
                      type="password"
                      id="sftpPassword"
                      name="sftpSettings.serverSettings.password"
                      label="Password"
                      placeholder=""
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-3">
                    <FormGroupInput
                      type="text"
                      id="sftpDirectory"
                      name="sftpSettings.serverSettings.directory"
                      label="Directory"
                      placeholder=""
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-3">
                    <button
                      className="button-filter mb-3"
                      type="button"
                      onClick={checkSftpConnection}
                      disabled={checkButtonIsDisabled}
                    >
                      {submitting ? "Checking..." : "Check"}
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <FormGroup>
                      <Label for="useSftpEmailTemplate">
                        Use SFTP email template
                      </Label>
                      <div className="checkbox-container-form">
                        <UICheckbox
                          id="useSftpEmailTemplate"
                          name="sftpSettings.emailTemplateSettings.useEmailTemplate"
                          title=""
                        />
                      </div>
                    </FormGroup>
                  </div>
                </div>
                {values.sftpSettings.emailTemplateSettings.useEmailTemplate && (
                  <>
                    <div className="row">
                      <div className="col-4">
                        <FormGroup>
                          <Label for="extesionField">Template format</Label>
                          <div>
                            <UIRadio
                              value="text/html"
                              name="sftpSettings.emailTemplateSettings.emailTemplateFormat"
                              title="HTML"
                            />
                            <UIRadio
                              value="text/plain"
                              name="sftpSettings.emailTemplateSettings.emailTemplateFormat"
                              title="Plain text"
                            />
                          </div>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-9">
                        <FormGroupInput
                          type="text"
                          id="sftpEmailSubjectTemplate"
                          name="sftpSettings.emailTemplateSettings.emailSubjectTemplate"
                          label="Email subject template"
                          placeholder="Enter template"
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                      <div className="col-3">
                        <h5>Available placeholders</h5>
                        <div className="item-placeholder">%subject%</div>
                        <div className="item-placeholder">%senttime%</div>
                        <div className="item-placeholder">%sender%</div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-9">
                        <FormGroup>
                          <Label for="sftpEmailTemplate">Email template</Label>
                          <Field
                            as="textarea"
                            className={
                              errors.sftpEmailTemplate &&
                              touched.sftpEmailTemplate
                                ? "form-control error-outline"
                                : "form-control"
                            }
                            id="sftpEmailTemplate"
                            placeholder="Enter template"
                            name="sftpSettings.emailTemplateSettings.emailTemplate"
                          />
                          {errors.sftpEmailTemplate &&
                          touched.sftpEmailTemplate ? (
                            <div className="error-field">
                              {errors.sftpEmailTemplate}
                            </div>
                          ) : null}
                        </FormGroup>
                      </div>
                      <div className="col-3">
                        <h5>Available placeholders</h5>
                        <div className="item-placeholder">%senttime%</div>
                        <div className="item-placeholder">%attachmentsurl%</div>
                        <div className="item-placeholder">%password%</div>
                        <div className="item-placeholder">%sender%</div>
                        <div className="item-placeholder">%allattachments%</div>
                        <div className="item-placeholder">
                          %protectedattachments%
                        </div>
                        <div className="item-placeholder">
                          %notprotectedattachments%
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
            {values.attachmentsDeliveryTransportType === OUTLOOK && (
              <div className="row">
                <div className="col-4">
                  <FormGroup>
                    <Label for="extesionField">
                      Use password email template
                    </Label>
                    <div className="checkbox-container-form">
                      <UICheckbox
                        id="usePasswordEmailTemplate"
                        name="usePasswordEmailTemplate"
                        title=""
                      />
                    </div>
                  </FormGroup>
                </div>
              </div>
            )}
            {values.attachmentsDeliveryTransportType === OUTLOOK &&
              values.usePasswordEmailTemplate && (
                <>
                  <div className="row">
                    <div className="col-4">
                      <FormGroup>
                        <Label for="extesionField">Template format</Label>
                        <div>
                          <UIRadio
                            value="text/html"
                            name="passwordEmailTemplateFormat"
                            title="HTML"
                          />
                          <UIRadio
                            value="text/plain"
                            name="passwordEmailTemplateFormat"
                            title="Plain text"
                          />
                        </div>
                      </FormGroup>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-9">
                      <FormGroupInput
                        type="text"
                        id="passwordEmailSubjectTemplate"
                        name="passwordEmailSubjectTemplate"
                        label="Email subject template"
                        placeholder="Enter template"
                        errors={errors}
                        touched={touched}
                      />
                    </div>
                    <div className="col-3">
                      <h5>Available placeholders</h5>
                      <div className="item-placeholder">%subject%</div>
                      <div className="item-placeholder">%senttime%</div>
                      <div className="item-placeholder">%sender%</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-9">
                      <FormGroup>
                        <Label for="passwordEmailTemplate">
                          Email template
                        </Label>
                        <Field
                          as="textarea"
                          className={
                            errors.passwordEmailTemplate &&
                            touched.passwordEmailTemplate
                              ? "form-control error-outline"
                              : "form-control"
                          }
                          id="passwordEmailTemplate"
                          placeholder="Enter template"
                          name="passwordEmailTemplate"
                        />
                        {errors.passwordEmailTemplate &&
                        touched.passwordEmailTemplate ? (
                          <div className="error-field">
                            {errors.passwordEmailTemplate}
                          </div>
                        ) : null}
                      </FormGroup>
                    </div>
                    <div className="col-3">
                      <h5>Available placeholders</h5>
                      <div className="item-placeholder">%senttime%</div>
                      <div className="item-placeholder">%password%</div>
                      <div className="item-placeholder">%sender%</div>
                      <div className="item-placeholder">%allattachments%</div>
                      <div className="item-placeholder">
                        %protectedattachments%
                      </div>
                      <div className="item-placeholder">
                        %notprotectedattachments%
                      </div>
                    </div>
                  </div>
                </>
              )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <FormGroup>
            <Label for="extesionField">
              Use ID detection by 13 digits pattern
            </Label>
            <div className="checkbox-container-form">
              <UICheckbox id="useBccEmail" name="useBccEmail" title="" />
            </div>
          </FormGroup>
        </div>
      </div>
      <div className="row">
        <div className="col-8">
          {values.useBccEmail && (
            <FormGroup className="without-margin">
              <Label for="bccEmailField">Bcc email list</Label>
              <div className="row">
                <div className="col-5 input-extenstion-list-col">
                  <Field
                    type="text"
                    className="form-control input-extenstion-list"
                    id="bccEmailField"
                    placeholder="Enter email"
                    name="bccEmailField"
                  />
                </div>
                <div className="col-3 button-extension-list-col">
                  <button
                    className="button-extension-list"
                    type="button"
                    onClick={addEmail}
                  >
                    Add email
                  </button>
                </div>
                <div className="col-12">
                  {errors.bccEmailField && touched.bccEmailField ? (
                    <div className="error-field">{errors.bccEmailField}</div>
                  ) : null}
                </div>
              </div>
            </FormGroup>
          )}
          {!values.useBccEmail && bccEmailAddresses.length > 0 && (
            <FormGroup className="without-margin">
              <Label>Bcc email list</Label>
            </FormGroup>
          )}
        </div>
        <div className="col-6 mb-3">
          {bccEmailAddresses.map((email: string, index: number) => (
            <div
              className={
                !values.useBccEmail ? "disabled-tag tag" : "enabled-tag tag"
              }
              key={index}
            >
              <span>{email}</span>
              {values.useBccEmail && (
                <span onClick={() => removeEmail(index)}>
                  <IoIosClose className="close-icon" />
                </span>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
