import {
  GET_USER_REQUEST,
  GET_USER_REQUEST_DONE,
  GET_USER_REQUEST_FAILED,
  GET_TENANT_REQUEST,
  GET_TENANT_REQUEST_DONE,
  GET_TENANT_REQUEST_FAILED,
  FINISH_LOAD,
  UPSERT_USER_REQUEST_DONE,
  UPSERT_USER_REQUEST,
  UPSERT_TENANT_REQUEST,
  UPSERT_TENANT_REQUEST_DONE,
  UPSERT_TENANT_REQUEST_FAILED,
  UPSERT_USER_REQUEST_FAILED
} from "../constants/useUpsertUserConstants";
import { AnyAction } from "redux";
import { IUserExtends } from "src/interfaces/IUser";
import { ITenantExtend } from "src/interfaces/ITenant";

export const getUserRequestAction: FuncWithoutParams<AnyAction> = () => ({
  type: GET_USER_REQUEST,
});

export const getUserRequestDoneAction: Func<IUserExtends, AnyAction> = (user: IUserExtends) => ({
  type: GET_USER_REQUEST_DONE,
  payload: user,
});

export const getUserRequestFailedAction: Func<string, AnyAction> = (e: string) => ({
  type: GET_USER_REQUEST_FAILED,
  payload: e,
});

export const getTenantRequestAction: FuncWithoutParams<AnyAction> = () => ({
  type: GET_TENANT_REQUEST,
});

export const getTenantRequestDoneAction: Func<ITenantExtend, AnyAction> = (tenant: ITenantExtend) => ({
  type: GET_TENANT_REQUEST_DONE,
  payload: tenant,
});

export const getTenantRequestFailedAction: Func<string, AnyAction> = (e: string) => ({
  type: GET_TENANT_REQUEST_FAILED,
  payload: e,
});

export const finishLoadAction: FuncWithoutParams<AnyAction> = () => ({
  type: FINISH_LOAD,
});

export const upsertUserRequestAction: FuncWithoutParams<AnyAction> = () => ({
  type: UPSERT_USER_REQUEST,
});

export const upsertUserRequestDoneAction: Func<IUserExtends, AnyAction> = (user: IUserExtends) => ({
  type: UPSERT_USER_REQUEST_DONE,
  payload: user,
});

export const upsertUserRequestFailedAction: Func<string, AnyAction> = (e: string) => ({
  type: UPSERT_USER_REQUEST_FAILED,
  payload: e,
});

export const upsertTenantRequestAction: FuncWithoutParams<AnyAction> = () => ({
  type: UPSERT_TENANT_REQUEST,
});

export const upsertTenantRequestDoneAction: Func<ITenantExtend, AnyAction> = (tenant: ITenantExtend) => ({
  type: UPSERT_TENANT_REQUEST_DONE,
  payload: tenant,
});

export const upsertTenantRequestFailedAction: Func<string, AnyAction> = (e: string) => ({
  type: UPSERT_TENANT_REQUEST_FAILED,
  payload: e,
});