import axios, { AxiosError, AxiosRequestConfig, AxiosInstance } from "axios";
import qs from "qs";
import { IRequestOptions } from "../interfaces/IRequestOptions";
import { getAuthToken } from "../utils/localStorage";

const HTTP: AxiosInstance = axios.create({
  withCredentials: false,
});

// tslint:disable-next-line: typedef
export const request = <T = any>(options: IRequestOptions) => {
  const {
    sendToken = true,
    contentType = "application/json",
    cacheControl = "no-cache",
    ...rest
  } = options;

  // tslint:disable-next-line: typedef
  const config = rest as AxiosRequestConfig;
  config.headers = config.headers || {};
  config.paramsSerializer = (prm: any) =>
    qs.stringify(prm, { arrayFormat: "repeat" });

  if (sendToken && getAuthToken()) {
    config.headers.Authorization = `Bearer ${getAuthToken()}`;
  }

  if (contentType !== "multipart/form-data") {
    config.headers["Content-Type"] = contentType;
  }

  config.headers["Cache-Control"] = cacheControl;

  HTTP.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        window.location.href = "/";
      }
      return Promise.reject(error);
    }
  );
  // tslint:disable-next-line: typedef
  const response = HTTP.request<T>(config);

  return response
    .then((res) => {
      if (res.status !== 200) {
        // tslint:disable-next-line: typedef
        const error = new Error() as AxiosError;
        error.response = res;
        throw error;
      }
      return res.data;
    })
    .catch((e: AxiosError) => {
      return Promise.reject(e.response);
    });
};

// tslint:disable-next-line: typedef
export const get = <T = any>(url: string, params?: PlainObject): Promise<T> =>
  request<T>({ method: "GET", url, params });

// tslint:disable-next-line: typedef
export const post = <T = any>(
  url: string,
  data?: PlainObject,
  params?: PlainObject
): Promise<T> => request<T>({ method: "POST", url, params, data });

// tslint:disable-next-line: typedef
export const put = <T = any>(
  url: string,
  data: PlainObject,
  params?: PlainObject
): Promise<T> => request<T>({ method: "PUT", url, params, data });

// tslint:disable-next-line: typedef
export const del = <T = any>(url: string, params?: PlainObject): Promise<T> =>
  request<T>({ method: "DELETE", url, params });
