import React, { FC } from "react";
import { ListGroupItem } from "reactstrap";
import { IFields } from "src/interfaces/IFields";
interface IProps {
  item: IFields;
  even: boolean;
}

export const UserInfoItem: FC<IProps> = ({item, even}) => {
  return (
    <>
     <ListGroupItem color={even ? "info" : ""}>
       <div className="row user-info-item">
         <div className="col-3">
           <span className="title">{item.title}</span>
         </div>
         <div className="col">
           <span className="value">{item.value || "-"}</span>
         </div>
       </div>
     </ListGroupItem>
    </>
  );
};