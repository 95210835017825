import { IHookTable } from "../../hooks/interfaces/IHookTable";
import { IFiltersListCompanies } from "../../interfaces/IFilterList";
import { ICompaniesList } from "../../interfaces/ICompanies";

export const INITIAL_STATE: IHookTable<ICompaniesList, IFiltersListCompanies> = {
  pagination: {
    page: 1,
    pageSize: 10,
  },
  sort: "tenantKey",
  direction: "asc",
  filters: {
    like: {},
    eq: {},
  },
  list: [],
  count: 0,
  isLoadingList: false,
};