import React, { FC } from "react";
import OperationsTable, { OperationsFilter } from "../../components/Operations";
import { useTableList } from "../../hooks/useTableList";
import { getAuditList } from "../../api/audit";
import { INITIAL_STATE } from "./InitialStateList";
import { IOperationsList } from "../../interfaces/IOperations";
import { IFiltersListOperations } from "../../interfaces/IFilterList";
import { IUseTableListReturn } from "src/hooks/interfaces/IHookTable";
import { Row } from "reactstrap";

export const OperationsPage: FC = () => {
  const operations: IUseTableListReturn<
    IOperationsList,
    IFiltersListOperations
  > = useTableList<IOperationsList, IFiltersListOperations>(
    getAuditList,
    INITIAL_STATE,
    "id"
  );

  const {
    list,
    pagination,
    count,
    direction,
    sort,
    setSort,
    setFilters,
    setPagination,
    setSizePerPage,
  } = operations;

  const [isFilterHidden, setIsFilterHidden] = React.useState<boolean>(false);

  return (
    <div className="operations-container">
      <h2 className="header-title">
        <span>
          {" "}
          <span className="mark-brand">E</span>mail Operations
        </span>
        <span className="underline"></span>
      </h2>
      <div className="tools-table">
        <Row>
          <h3 className="col-9">Filters</h3>
          <div
            className="col-3"
            style={{ cursor: "pointer", fontSize: "24px", textAlign: "right" }}
            onClick={(e) => {
              setIsFilterHidden(!isFilterHidden);
            }}
          >
            {isFilterHidden
              ? String.fromCharCode(9660)
              : String.fromCharCode(9650)}
          </div>
        </Row>

        {!isFilterHidden && <OperationsFilter filters={setFilters} />}
      </div>

      <div className="table-content">
        <OperationsTable
          setSizePerPage={setSizePerPage}
          list={list}
          direction={direction}
          sort={sort}
          setSort={setSort}
          pagination={setPagination}
          page={pagination.page}
          pageSize={pagination.pageSize}
          count={count}
        />
      </div>
    </div>
  );
};
