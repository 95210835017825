import React, { FC, useState } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";

export const Tabs: FC<any> = ({ children }) => {
  const [activeTab, setActiveTab] = useState<string>("1");

  const toggle: any = (tab: string) => {
    if (activeTab !== tab) { setActiveTab(tab); }
  };
  return (
    <>
      <Nav tabs>
        <NavItem>
          <NavLink
            onClick={() => { toggle("1") }}
            className={activeTab === "1" ? "active" : ""}
          >
            User
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            onClick={() => { toggle("2") }}
            className={activeTab === "2" ? "active" : ""}
          >
            Tenant
          </NavLink>
        </NavItem>
      </Nav>
      {children(activeTab, toggle)}
    </>
  );
};