import React, { FC } from "react";
import FormGroupInput from "../UI/FormGroupInput";
import { IFiltersListCompanies } from "../../interfaces/IFilterList";
import { Filters } from "../Filters";

interface IProps {
  filters: (filters: IFiltersListCompanies) => void;
}

interface IValues {
  tenant: string;
  tenantKey: string;
}

export const CompaniesFilter: FC<IProps> = ({ filters }) => {

  const INITIAL_VALUES: IValues = {
    tenant: "",
    tenantKey: "",
  };

  const formatter: any = (values: IValues): IFiltersListCompanies => {
    return {
      like: {
        tenant: (values && values.tenant) || null,
      },
      eq: {
        tenantKey: (values && values.tenantKey) || null,
      }
    };
  };

  return (
    <>
      <Filters initialValues={INITIAL_VALUES} filters={filters} formatter={formatter}>
        {
          ({errors, touched}: any) => (
            <div className="row">
          <div className="col-3">
            <FormGroupInput
              type="text"
              id="tenant"
              name="tenant"
              label="Tenant"
              placeholder=""
              errors={errors}
              touched={touched}
            />
          </div>
          <div className="col-3">
            <FormGroupInput
              type="text"
              id="tenantKey"
              name="tenantKey"
              label="Tenant key"
              placeholder=""
              errors={errors}
              touched={touched}
            />
          </div>
        </div>
          )
        }
      </Filters>
    </>
  );
};