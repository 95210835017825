import React, { FC } from "react";
import { UserInfoItem } from "./UserInfoItem";
import { ListGroup } from "reactstrap";
import { IFields } from "src/interfaces/IFields";

interface IProps {
  userInfo: IFields[];
}

export const UserInfo: FC<IProps> = ({userInfo}) => {
  return (
    <>
      <ListGroup>
        {userInfo.map((item: IFields, index: number) => (
          <UserInfoItem key={index} item={item} even={Boolean(index % 2)} />
        ))}
      </ListGroup>
    </>
  );
};
