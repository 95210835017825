import React, { FC } from "react";
import FormGroupInput from "../UI/FormGroupInput";
import { IFiltersListOperations } from "../../interfaces/IFilterList";
import { Filters } from "../Filters";
import { FormGroup, Label } from "reactstrap";
import { Field } from "formik";
import { ID_DETECTION_STATUS } from "../../constants/IdDetectionStatus";
import { TRANSPORT_LABELS } from "../../constants/attachmentsTransport";
import moment from "moment";

interface IProps {
  filters: (filters: IFiltersListOperations) => void;
}

interface IValues {
  from: string;
  to: string;
  tenantKey: string;
  company: string;
  location: string;
  status: string;
  idNumberDetectionStatus: string;
  attachmentsDeliveryTransportType: string;
  attachmentsName: string;
  createdAtAfter: string;
  createdAtBefore: string;
}

export const OperationsFilter: FC<IProps> = ({ filters }) => {
  const INITIAL_VALUES: IValues = {
    from: "",
    to: "",
    tenantKey: "",
    company: "",
    location: "",
    status: "",
    idNumberDetectionStatus: "",
    attachmentsDeliveryTransportType: "",
    attachmentsName: "",
    createdAtAfter: "",
    createdAtBefore: "",
  };

  const formatter: any = (values: IValues): IFiltersListOperations => {
    return {
      like: {
        from: (values && values.from) || null,
        to: (values && values.to) || null,
        location: (values && values.location) || null,
        company: (values && values.company) || null,
        attachmentsName: (values && values.attachmentsName) || null,
        createdAt:
          (values &&
            (values.createdAtAfter || values.createdAtBefore) &&
            (values.createdAtAfter
              ? moment(values.createdAtAfter).format("YYYY/MM/DD")
              : "") +
              "-" +
              (values.createdAtBefore
                ? moment(values.createdAtBefore).format("YYYY/MM/DD")
                : "")) ||
          null,
      },
      eq: {
        status: (values && values.status) || null,
        tenantKey: (values && values.tenantKey) || null,
        idNumberDetectionStatus:
          (values && values.idNumberDetectionStatus) || null,
        attachmentsDeliveryTransportType:
          (values && values.attachmentsDeliveryTransportType) || null,
      },
    };
  };

  return (
    <>
      <Filters
        initialValues={INITIAL_VALUES}
        filters={filters}
        formatter={formatter}
      >
        {({ errors, touched }: any) => (
          <div className="row">
            <div className="col-3">
              <FormGroupInput
                type="text"
                id="from"
                name="from"
                label="From"
                placeholder=""
                errors={errors}
                touched={touched}
              />
            </div>
            <div className="col-3">
              <FormGroupInput
                type="text"
                id="to"
                name="to"
                label="To"
                placeholder=""
                errors={errors}
                touched={touched}
              />
            </div>
            <div className="col-3">
              <FormGroupInput
                type="text"
                id="tenantKey"
                name="tenantKey"
                label="Tenant key"
                placeholder=""
                errors={errors}
                touched={touched}
              />
            </div>
            <div className="col-3">
              <FormGroupInput
                type="text"
                id="company"
                name="company"
                label="Company"
                placeholder=""
                errors={errors}
                touched={touched}
              />
            </div>
            <div className="col-3">
              <FormGroupInput
                type="text"
                id="location"
                name="location"
                label="Location"
                placeholder=""
                errors={errors}
                touched={touched}
              />
            </div>
            <div className="col-3">
              <FormGroup>
                <Label for="status">Status</Label>
                <Field as="select" name="status" className="form-control">
                  <option value="">All</option>
                  <option value="success">Success</option>
                  <option value="failure">Error</option>
                </Field>
              </FormGroup>
            </div>
            <div className="col-3">
              <FormGroup>
                <Label for="idNumberDetectionStatus">ID detection status</Label>
                <Field
                  as="select"
                  name="idNumberDetectionStatus"
                  className="form-control"
                >
                  <option value="">All</option>
                  {Object.entries(ID_DETECTION_STATUS).map(([key, value]) => (
                    <option value={key} key={key}>
                      {value}
                    </option>
                  ))}
                </Field>
              </FormGroup>
            </div>
            <div className="col-3">
              <FormGroup>
                <Label for="attachmentsDeliveryTransportType">
                  Attachments delivery transport type
                </Label>
                <Field
                  as="select"
                  name="attachmentsDeliveryTransportType"
                  className="form-control"
                >
                  <option value="">All</option>
                  {Object.entries(TRANSPORT_LABELS).map(([key, value]) => (
                    <option value={key} key={key}>
                      {value}
                    </option>
                  ))}
                </Field>
              </FormGroup>
            </div>
            <div className="col-3">
              <FormGroupInput
                type="text"
                id="attachmentsName"
                name="attachmentsName"
                label="Attachment name"
                placeholder=""
                errors={errors}
                touched={touched}
              />
            </div>
            <div className="col-3">
              <FormGroupInput
                type="date"
                id="createdAtAfter"
                name="createdAtAfter"
                label="Created at after"
                placeholder=""
                errors={errors}
                touched={touched}
              />
            </div>
            <div className="col-3">
              <FormGroupInput
                type="date"
                id="createdAtBefore"
                name="createdAtBefore"
                label="Created at before"
                placeholder=""
                errors={errors}
                touched={touched}
              />
            </div>
          </div>
        )}
      </Filters>
    </>
  );
};
