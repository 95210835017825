import { post } from "./request";
import { IOperation } from "../interfaces/IOperations";
import { IFilterLikeOperation, IFilterEqOperation } from "../interfaces/IFilterList";
import { IRequestListParams } from "../interfaces/IRequestListParams";
import { IResponseList } from "../interfaces/IResponseList";

const BASE: string = "/audit";

export const createAudit: FuncPromise<IOperation, string> =
  (data: IOperation): Promise<string> => (
    post(`${BASE}`, data)
  );

export const getAuditList: FuncPromise<IRequestListParams<IFilterLikeOperation, IFilterEqOperation>> =
  (data: IRequestListParams<IFilterLikeOperation, IFilterEqOperation>):
    Promise<IResponseList<IFilterLikeOperation, IFilterEqOperation, IOperation>> => (
      post(`${BASE}/query`, data)
    );