import React, { FC } from "react";
import FormGroupInput from "../UI/FormGroupInput";
import { FormikErrors, FormikTouched } from "formik";
import { ILoginForm } from "../../interfaces/ILoginForm";

interface IProps {
  errors: FormikErrors<ILoginForm>;
  touched: FormikTouched<ILoginForm>;
}

export const Login: FC<IProps> = ({ errors, touched }) => {

  return (
    <>
      <div className="col-12">
        <div className="form-group">
          <FormGroupInput
            type="text"
            id="email"
            name="email"
            label="Email address"
            placeholder="Enter email"
            errors={errors}
            touched={touched}
          />
          <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.
            </small>
        </div>
      </div>
      <div className="col-12">
        <div className="form-group">
          <FormGroupInput
            type="password"
            id="password"
            name="password"
            label="Password"
            placeholder="Enter password"
            errors={errors}
            touched={touched}
          />
        </div>
      </div>
    </>
  );
};