import { applyMiddleware, createStore, Middleware, Store } from "redux";
import { history } from "../utils/history";

import { routerMiddleware } from "connected-react-router";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import reducers from "./reducers";


const composeEnhancers: any = composeWithDevTools({});

const INITIAL_STATE: any = {};

const middlewares: Middleware[] = [
  routerMiddleware(history),
];

const store: Store = createStore(reducers(history), INITIAL_STATE, composeEnhancers(applyMiddleware(...middlewares)));

export default store;