import { useReducer, useEffect } from "react";
import { INITIAL_STATE, reducer } from "./reducers/useSftpReducer";
import {
  checkSftpRequestAction,
  checkSftpRequestDoneAction,
  checkSftpRequestFailedAction
} from "./actions/useSftpActions";
import { checkSftpConnection } from "../api/sftp";

export const useSftp: FuncWithoutParams<any> = () => {
  const [ state, dispatch ] = useReducer(reducer, INITIAL_STATE);
  const { submitting, error, sftp, complete } = state;
  const checkSftp = async (sftp: any) => {
    dispatch(checkSftpRequestAction());
    try {
      const result = await checkSftpConnection(sftp);
      dispatch(checkSftpRequestDoneAction(result));
    } catch (error) {
      dispatch(checkSftpRequestFailedAction(error));
    }
  };

  return {
    submitting,
    error,
    sftp,
    checkSftp,
    complete,
  };
};
