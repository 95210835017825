import { post, get } from "./request";
import { IUser, IUserExtends } from "../interfaces/IUser";

const BASE: string = "/users";

export const getCurrentUser: FuncPromiseWithoutParams<IUserExtends> = (): Promise<IUserExtends> => (
  get(`${BASE}/me`)
);

export const getUser: FuncPromise<string, IUserExtends> = (id: string): Promise<IUserExtends> => (
  get(`${BASE}/${id}`)
);

export const createUser: FuncPromise<IUser, IUserExtends> = (data: IUser): Promise<IUserExtends> => (
  post(`${BASE}/new`, data)
);

export const updateUser: FuncPromise<IUser, IUserExtends> = (data: IUser): Promise<IUserExtends> => (
  post(`${BASE}/update`, data)
);