import React, { FC } from "react";
import { Spinner } from "reactstrap";

interface IProps {
  classNameWrap?: string;
}
export const SpinnerLoader: FC<IProps> = ({classNameWrap = "spinner-back"}) => {

  return (
    <div className={classNameWrap}>
      <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
    </div>
  );
};