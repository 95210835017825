import React, { FC } from "react";
import { FormGroup, Input, Row, Table } from "reactstrap";
import Pagination from "../Pagination";
export const TableList: FC<any> = (props) => {
  const { children, page, pageSize, pagination, count, setSizePerPage } = props;

  // tslint:disable-next-line: typedef
  const pageChange = (value: string | number) => {
    if (value === "+") {
      pagination(page + 1);
      return;
    }

    if (value === "-") {
      pagination(page - 1);
      return;
    }

    pagination(value);
  };

  return (
    <>
      {count > 0 && (
        <>
          <div className="status-bar">
            <div className="count">
              Page {page} from {Math.ceil(count / pageSize)}
            </div>
          </div>
        </>
      )}
      <Table responsive striped>
        {children}
      </Table>
      <Row style={{ display: "flex", justifyContent: "center" }}>
        <div
          className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-4"
          style={{ marginTop: "20px" }}
        >
          <FormGroup>
            <Input
              style={{ width: "100px" }}
              id="entriesPerPageSelect"
              name="select"
              type="select"
              value={pagination.pageSize}
              onChange={(e) => {
                setSizePerPage(parseInt(e.target.value));
              }}
            >
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={500}>500</option>
            </Input>
          </FormGroup>
        </div>
        {count > 0 && (
          <Pagination
            className="col-3"
            page={page}
            total={count}
            pageSize={pageSize}
            onChange={pageChange}
          />
        )}
      </Row>
    </>
  );
};
