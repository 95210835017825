import React, { FC } from "react";
import { Field } from "formik";
import { CheckboxRaw } from "./CheckboxRaw";

export const UICheckbox: FC<any> = ({ id, name, className, title }) => {
  return (
    <>
      <Field
        name={name}
        id={id}
        title={title}
        component={CheckboxRaw}
      />
    </>
  );
};