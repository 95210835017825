import React, { FC } from "react";
import { CompaniesTd } from "./CompaniesTd";
import { TableList } from "../Table";
import { Sorting } from "../Sorting";
export const CompaniesTable: FC<any> = (props) => {
  const {
    list,
    flat,
    page,
    direction,
    sort,
    setSort,
    pageSize,
    pagination,
    updateStatusCompanies,
    count,
    setSizePerPage,
  } = props;

  return (
    <>
      <TableList
        setSizePerPage={setSizePerPage}
        typeHead="companies"
        page={page}
        pageSize={pageSize}
        pagination={pagination}
        count={count}
      >
        <thead>
          <tr>
            <Sorting
              name="tenantKey"
              setSort={setSort}
              direction={direction}
              sort={sort}
              classTh="text-overflow-th large-th-width"
            >
              Tenant key
            </Sorting>
            <Sorting
              name="tenant"
              setSort={setSort}
              direction={direction}
              sort={sort}
              classTh="text-overflow-th small-th-width"
            >
              Tenant
            </Sorting>
            <th>
              <div className="text-overflow-th medium-th-width">Issued at</div>
            </th>
            <Sorting
              name="lastChangedAt"
              setSort={setSort}
              direction={direction}
              sort={sort}
              classTh="text-overflow-th large-th-width"
            >
              Last changed at
            </Sorting>
            <th>
              <div className="text-overflow-th medium-th-width">Valid thru</div>
            </th>
            <Sorting
              name="isEnabled"
              setSort={setSort}
              direction={direction}
              sort={sort}
              classTh="text-overflow-th small-th-width"
            >
              Enabled
            </Sorting>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {list.length > 0 ? (
            list.map((elem: any, index: number) => (
              <CompaniesTd
                key={index}
                item={elem}
                flatItem={flat[elem.tenantKey]}
                updateStatusCompanies={updateStatusCompanies}
              />
            ))
          ) : (
            <tr>
              <td className="empty-row" colSpan={8}>
                Data not found
              </td>
            </tr>
          )}
        </tbody>
      </TableList>
    </>
  );
};
