import React, { FC, useContext, useEffect } from "react";
import { FormikHelpers, Formik, Form } from "formik";
import { AppContext } from "../../context/AppContext";
import { Spinner } from "reactstrap";
import { toast } from "react-toastify";
import { validationSchema } from "../../validation/registerValidation";
import { IHookAppReturn } from "../../hooks/interfaces/IHookAppState";
import { ILoginForm } from "../../interfaces/ILoginForm";
import { history } from "../../utils/history";
import { AddUserForm } from "../../components/AddUser/AddUserForm";
import { signUp } from "../../api";

interface IRegisterForm {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
  gender: string;
  location: string;
  country: string;
  occupation: string;
  address: string;
  website: string;
  mobile: string;
  position: string;
  company: string;
  surname: string;
}

export const RegisterPage: FC = () => {

  const { isLoadingToken, error } = useContext<IHookAppReturn>(AppContext);

  const INITIAL_VALUES: IRegisterForm = {
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    gender: "Male",
    location: "",
    country: "",
    occupation: "",
    address: "",
    website: "",
    mobile: "",
    position: "",
    company: "",
    surname: "",
  };

  const onSubmit: IFormikSubmit<IRegisterForm> = (values: IRegisterForm, { setSubmitting }: FormikHelpers<IRegisterForm>) => {
    setSubmitting(false);
    signUp(values);
    history.push("/login");
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <div className="login-page register-page container">
      <div className="row">
        <div className="col"></div>
        <div className="col-12 col-sm-12 col-md-10 login-container register-container">
          <Formik
            initialValues={INITIAL_VALUES}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {(formProps) => (
              <Form>
                <div className="row">
                  <div className="col-12">
                    <h3><span className="mark-brand">P</span>ryvicy</h3>
                  </div>
                </div>
                <AddUserForm {...formProps}/>
                <div className="row">
                  <div className="col-12">
                    {
                      !isLoadingToken ? (
                        <button type="submit" className="button-login">Submit</button>
                      ) : (
                          <>
                            <button type="submit" className="button-login">
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                              <span className="mr-2">Loading...</span>
                            </button>
                          </>
                        )
                    }
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className="col"></div>
      </div>
    </div>
  );
};