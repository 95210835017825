import React, { FC, useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { IHookAppReturn } from "../../hooks/interfaces/IHookAppState";
import AddUser from "../AddUser";
import { validationSchema } from "../../validation/editUserValidation";
import Tabs from "../../components/Tabs";
import { useUpsertUser } from "../../hooks/useUpsertUser";
export const UserInfoEdit: FC = () => {

  const appState: any = useContext<IHookAppReturn>(AppContext);

  const { isLoading, user, tenant, upsertUser, complete, submitting, error }: any = useUpsertUser(appState.user.tenantKey, true);

  if (isLoading) {
    return <>Loading...</>;
  }

  return (
    <div className="add-user-container">
      <Tabs>
        {(activeTab: string, toggle: any) => (
          <>
            <AddUser
              editable={true}
              activeTab={activeTab}
              toggleTab={toggle}
              isCurrentUser={true}
              user={user}
              tenant={tenant}
              upsert={upsertUser}
              validation={validationSchema}
              error={error}
              complete={complete}
              submitting={submitting} />
          </>
        )}
      </Tabs>
    </div>
  );
};