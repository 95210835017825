
import React, { FC, useContext } from "react";

import { Redirect, Route, RouteProps } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { IHookAppReturn } from "src/hooks/interfaces/IHookAppState";

export const PrivateRoute: FC<RouteProps> = (props) => {
  const {
    component: RouteComponent,
    ...passProps
  } = props;

  const { token } = useContext<IHookAppReturn>(AppContext);

  if (!token) {
    return (
      <Redirect to="/login" />
    );
  }

  return (
    <Route {...passProps} component={RouteComponent}/>
  );
};