import React, { FC, useState, useEffect } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

export const Pageable: FC<any> = (props) => {
  const {
    page,
    total,
    pageSize,
    onChange,
  } = props;

  const [pagesList, setPageList] = useState<number[]>([]);
  const [startPage, setStartPage] = useState<number>(1);
  const countOfPage = 10;

  useEffect(() => {
    console.log(page);
    if((page === pagesList[0]) && page > 1 ) {
      setStartPage(startPage-1);
    } else if((page === pagesList[pagesList.length -1]) && page < Math.ceil((total / pageSize))) {
      setStartPage(startPage+1);
    } else if(page === 1) {
      setStartPage(1);
    } else if(page === Math.ceil((total / pageSize)) && ((Math.ceil((total / pageSize)) - countOfPage ) > 0)) {
      setStartPage(Math.ceil((total / pageSize))  - (countOfPage-1));
    }
    if(total) {
      let pages: any = [];
      for(let i: number = startPage, j = 0; i <= Math.ceil((total / pageSize)) && j < countOfPage ; i++, j++) {
        pages.push(i);
      }
      setPageList(pages);
    }
  }, [total, pageSize, page, startPage]);

  return (
    <>
      <Pagination aria-label="Page navigation example">

        <PaginationItem>
          <PaginationLink first onClick={() => onChange(1)} disabled={page === 1} />
        </PaginationItem>

        <PaginationItem>
          <PaginationLink previous onClick={() => onChange("-")} disabled={page === 1} />
        </PaginationItem>

        {
          pagesList.map((item: any, index: number) => (
            <PaginationItem key={index}>
              <PaginationLink onClick={() => onChange(item)} className={item === page ? "active" : "inactive"}>
                {item}
              </PaginationLink>
            </PaginationItem>
          ))
        }

        <PaginationItem>
          <PaginationLink next onClick={() => onChange("+")} disabled={page === pagesList.length} />
        </PaginationItem>

        <PaginationItem>
          <PaginationLink last onClick={() => onChange(Math.ceil((total / pageSize)))} disabled={page === Math.ceil((total / pageSize))} />
        </PaginationItem>

      </Pagination>
    </>
  );
};