import { INITIAL_STATE, reducer } from "./reducers/useUpsertUserReducer";
import { useReducer, useEffect } from "react";
import {
  getUserRequestAction,
  getUserRequestDoneAction,
  getUserRequestFailedAction,
  getTenantRequestAction,
  getTenantRequestDoneAction,
  getTenantRequestFailedAction,
  upsertUserRequestAction,
  upsertUserRequestDoneAction,
  upsertUserRequestFailedAction,
  upsertTenantRequestAction,
  upsertTenantRequestDoneAction,
  upsertTenantRequestFailedAction,
  finishLoadAction,
} from "./actions/useUpsertUserActions";
import { getTenant, getTenantAdmin, createTenant } from "../api/tenant";
import { createUser, updateUser, getCurrentUser } from "../api";
import { errorMiddleware } from "../store/middleware/error.middleware";


// tslint:disable-next-line: typedef
export const useUpsertUser = (tenantKey?: string, current?: boolean) => {
  const [ state, dispatch ] = useReducer(reducer, INITIAL_STATE);

  const {
    user,
    tenant,
    isTenantLoading,
    isUserLoading,
    error,
    complete,
    submitting,
  } = state;

  const finishLoad = () => {
    dispatch(finishLoadAction());
  }

  const getUserTenant = async () => {
    dispatch(getUserRequestAction());

    try {
      const result = current ? await getCurrentUser() : await getTenantAdmin(tenantKey || "");
      dispatch(getUserRequestDoneAction(result));
      getTenantForAdmin();
    } catch (e) {
      dispatch(getUserRequestFailedAction(e));
    }
  }

  const updateTenantAdmin = async (tenant: any, tenantKey?: any) => {
    dispatch(upsertTenantRequestAction());

    try {
      const result = await createTenant(tenantKey ? {...tenant, tenantKey: tenantKey} : tenant);
      dispatch(upsertTenantRequestDoneAction(result));
    } catch (e) {
      dispatch(upsertTenantRequestFailedAction(e));
    }
  }

  const createUserAdmin = async (user: any, tenant: any) => {
    dispatch(upsertUserRequestAction());

    try {
      const result = await createUser(user);
      dispatch(upsertUserRequestDoneAction(result));
      await updateTenantAdmin(tenant, result.tenantKey);
    } catch (e) {
      console.log(e);
      dispatch(upsertUserRequestFailedAction(e));
    }
  }

  const updateUserAdmin = async (user: any, tenant: any) => {
    dispatch(upsertUserRequestAction());

    try {
      const result = await updateUser(user);
      dispatch(upsertUserRequestDoneAction(result));
      await updateTenantAdmin(tenant);
    } catch (e) {
      dispatch(upsertUserRequestFailedAction(e));
    }
  }

  const upsertUser = async (user: any, tenant: any) => {
    console.log(user);
    if(user.id) {
      await updateUserAdmin(user, tenant);
    } else {
      await createUserAdmin(user, tenant);
    }
  }

  const getTenantForAdmin = async () => {
    dispatch(getTenantRequestAction());

    try {
      const result = await getTenant(tenantKey || "");
      dispatch(getTenantRequestDoneAction(result));
      finishLoad();
    } catch(e) {
      dispatch(getTenantRequestFailedAction(e));
    }
  }

  useEffect(() => {
    if(tenantKey) {
      getUserTenant();
    }
  }, []);

  useEffect(() => {
    errorMiddleware(state);
  }, [state]);

  return {
    user,
    tenant,
    isTenantLoading,
    isUserLoading,
    getUserTenant,
    getTenantForAdmin,
    finishLoad,
    upsertUser,
    error,
    complete,
    submitting,
    isLoading: (isUserLoading && isTenantLoading) || !user || !tenant,
  }
}