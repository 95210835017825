
import {
  CHECK_SFTP_REQUEST,
  CHECK_SFTP_REQUEST_DONE,
  CHECK_SFTP_REQUEST_FAILED
} from "../constants/useSftpConstants";
import produce from "immer";
import { AnyAction } from "redux";

interface IHookSftp {
  sftp: any;
  error: any;
  submitting: boolean;
  complete: boolean;
}

export const INITIAL_STATE: IHookSftp = {
  sftp: undefined,
  error: null,
  submitting: false,
  complete: false,
};

// tslint:disable-next-line: typedef
export const reducer = (state: IHookSftp, action: AnyAction) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case CHECK_SFTP_REQUEST:
        draft.complete = false;
        draft.submitting = true;
        draft.error = null;
        break;
      case CHECK_SFTP_REQUEST_DONE:
        draft.complete = true;
        draft.submitting = false;
        draft.sftp = action.payload;
        break;
      case CHECK_SFTP_REQUEST_FAILED:
        draft.submitting = false;
        draft.error = action.payload;
        break;
    }
  });
};
