import React, { FC } from "react";
import Tabs from "../../components/Tabs";
import AddUser from "../../components/AddUser";
import { useUpsertUser } from "../../hooks/useUpsertUser";
import { useParams } from "react-router-dom";
import { validationSchema } from "../../validation/editUserValidation";

export const EditUserPage: FC = () => {
  const { id = "" } = useParams();

  const { isLoading, user, tenant, upsertUser, complete, submitting, error }: any = useUpsertUser(id);

  if (isLoading) {
    return <>Loading...</>;
  }

  return (
    <div className="add-user-container">
      <h2 className="header-title">
        <span> <span className="mark-brand">E</span>dit User</span>
        <span className="underline"></span>
      </h2>
      <Tabs>
        {(activeTab: string, toggle: any) => (
          <>
            <AddUser
              editable={true}
              activeTab={activeTab}
              toggleTab={toggle}
              user={user}
              tenant={tenant}
              upsert={upsertUser}
              validation={validationSchema}
              error={error}
              complete={complete}
              submitting={submitting} />
          </>
        )}
      </Tabs>
    </div>
  );
};