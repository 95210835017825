import React, { FC } from "react";

interface IProps {
  field: any;
  title: string;
  value: string;
}
export const RadioRaw: FC<IProps> = ({ field, title, value }) => {
  const id = `${field.name}_${value}`;
  return (
    <div>
      <input
        id={id}
        name={field.name}
        type="radio"
        value={value}
        checked={value === field.value}
        onChange={field.onChange}
        onBlur={field.onBlur}
        className="radio"
      />
      <label htmlFor={id}>{title}</label>
    </div>
  );
}
