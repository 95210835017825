export const GET_USER_REQUEST: string = "GET_USER_REQUEST";
export const GET_USER_REQUEST_DONE: string = "GET_USER_REQUEST_DONE";
export const GET_USER_REQUEST_FAILED: string = "GET_USER_REQUEST_FAILED";

export const GET_TENANT_REQUEST: string = "GET_TENANT_REQUEST";
export const GET_TENANT_REQUEST_DONE: string = "GET_TENANT_REQUEST_DONE";
export const GET_TENANT_REQUEST_FAILED: string = "GET_TENANT_REQUEST_FAILED";

export const UPSERT_USER_REQUEST: string = "UPSERT_USER_REQUEST";
export const UPSERT_USER_REQUEST_DONE: string = "UPSERT_USER_REQUEST_DONE";
export const UPSERT_USER_REQUEST_FAILED: string = "UPSERT_USER_REQUEST_FAILED";

export const UPSERT_TENANT_REQUEST: string = "UPSERT_TENANT_REQUEST";
export const UPSERT_TENANT_REQUEST_DONE: string = "UPSERT_TENANT_REQUEST_DONE";
export const UPSERT_TENANT_REQUEST_FAILED: string = "UPSERT_TENANT_REQUEST_FAILED";

export const FINISH_LOAD: string = "FINISH_LOAD";

