import produce from "immer";
import { AnyAction } from "redux";

import {
  START_APP,
  SIGN_IN_FAILED,
  SIGN_IN_REQUEST,
  WRITE_TOKEN,
  WRITE_USER,
  FINISH_LOADING,
  LOGOUT,
  CURRENT_USER_REQUEST
} from "../constants/useAppConstants";
import { IHookAppState } from "../interfaces/IHookAppState";


export const INITIAL_STATE: IHookAppState = {
  token: undefined,
  user: undefined,
  isLoadingUser: true,
  isLoadingToken: true,
  error: null
};

export const reducer: any = (state: IHookAppState, action: AnyAction) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case START_APP:
        draft.isLoadingToken = true;
        draft.isLoadingUser = true;
        break;
      case WRITE_TOKEN:
        draft.token = action.payload;
        break;
      case WRITE_USER:
        draft.user = action.payload;
        break;
      case SIGN_IN_REQUEST:
        draft.isLoadingToken = true;
        break;
      case SIGN_IN_FAILED:
        draft.error = action.payload;
        break;
      case CURRENT_USER_REQUEST:
        draft.isLoadingUser = true;
        break;
      case LOGOUT:
        draft.user = undefined;
        draft.token = undefined;
        draft.error = null;
        draft.isLoadingUser = false;
        draft.isLoadingToken = false;
        break;
      case FINISH_LOADING:
        draft.isLoadingUser = false;
        draft.isLoadingToken = false;
        draft.error = null;
        break;
    }
  });
};