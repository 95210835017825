import { post, get } from "./request";
import { ITenant, ITenantExtend, ITenantStatus } from "../interfaces/ITenant";

const BASE: string = "/tenant";

export const createTenant: FuncPromise<ITenant, ITenantExtend> =
  (tenant: ITenant): Promise<ITenantExtend> => (
    post(`${BASE}`, tenant)
  );

export const getTenant: FuncPromise<string, ITenantExtend> =
  (tenantKey: string): Promise<ITenantExtend> => (
    get(`${BASE}/${tenantKey}`)
  );

export const getTenantAdmin: FuncPromise<string, any> =
  (tenantKey: string): Promise<any> => (
    get(`${BASE}/${tenantKey}/admin`)
  );

export const getTenantStatus: FuncPromise<string, ITenantStatus> =
  (tenantKey: string): Promise<ITenantStatus> => (
    get(`${BASE}/${tenantKey}/status`)
  );