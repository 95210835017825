import React, { FC } from "react";
import { Field, FormikTouched, FormikErrors } from "formik";


interface IProps {
  type: string;
  name: string;
  placeholder: string;
  errors: FormikErrors<any>;
  touched: FormikTouched<any>;
}
export const FormGroupInputField: FC<IProps> = (props) => {
  const {
    type = "text",
    name,
    placeholder = "Enter field",
    errors = {},
    touched = {},
  } = props;

  return (
    <>
      <Field
        type={type}
        className={errors[name] && touched[name] ? "form-control error-outline" : "form-control"}
        id={name}
        placeholder={placeholder}
        name={name}
      />
    </>
  );
};