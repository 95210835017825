import { AnyAction } from "redux";
import {
  CHECK_SFTP_REQUEST,
  CHECK_SFTP_REQUEST_DONE,
  CHECK_SFTP_REQUEST_FAILED
} from "../constants/useSftpConstants";
import { ISftpServerStatus } from "../../interfaces/ITenant";


export const checkSftpRequestAction: FuncWithoutParams<AnyAction> = () => ({
  type: CHECK_SFTP_REQUEST,
});

export const checkSftpRequestDoneAction: Func<ISftpServerStatus, AnyAction> = (sftp: ISftpServerStatus) => ({
  type: CHECK_SFTP_REQUEST_DONE,
  payload: sftp,
});

export const checkSftpRequestFailedAction: Func<string, AnyAction> = (error: string) => ({
  type: CHECK_SFTP_REQUEST_FAILED,
  payload: error,
});
