import {
  START_APP,
  WRITE_TOKEN,
  WRITE_USER,
  FINISH_LOADING,
  CURRENT_USER_FAILED,
  CURRENT_USER_REQUEST,
  SIGN_IN_REQUEST,
  SIGN_IN_FAILED,
  LOGOUT
} from "../constants/useAppConstants";
import { IUserExtends } from "src/interfaces/IUser";
import { AnyAction } from "redux";

export const startAppAction: FuncWithoutParams<AnyAction> = () => ({
  type: START_APP,
});

export const writeTokenAction: Func<string, AnyAction> = (token: string) => ({
  type: WRITE_TOKEN,
  payload: token,
});

export const writeUserAction: Func<IUserExtends, AnyAction> = (user: IUserExtends) => ({
  type: WRITE_USER,
  payload: user,
});

export const finishLoadAction: FuncWithoutParams<AnyAction> = () => ({
  type: FINISH_LOADING,
});

export const getUserActionRequest: FuncWithoutParams<AnyAction> = () => ({
  type: CURRENT_USER_REQUEST,
});

export const getUserActionRequestFailed: Func<string, AnyAction> = (errorMessage: string) => ({
  type: CURRENT_USER_FAILED,
  payload: errorMessage,
});

export const signInActionRequest: FuncWithoutParams<AnyAction> = () => ({
  type: SIGN_IN_REQUEST,
});

export const signInActionRequestFailed: Func<string, AnyAction> = (errorMessage: string) => ({
  type: SIGN_IN_FAILED,
  payload: errorMessage,
});

export const logoutAction: FuncWithoutParams<AnyAction> = () => ({
  type: LOGOUT,
});