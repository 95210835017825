import React, { FC, useContext, useEffect } from "react";
import { FormikHelpers, Formik, Form } from "formik";
import Login from "../../components/Login";
import { AppContext } from "../../context/AppContext";
import { Spinner } from "reactstrap";

import { toast } from "react-toastify";
import { validationSchema } from "../../validation/loginValidation";
import { IHookAppReturn } from "../../hooks/interfaces/IHookAppState";
import { ILoginForm } from "../../interfaces/ILoginForm";

export const LoginPage: FC = () => {

  const { login, isLoadingToken, error } = useContext<IHookAppReturn>(AppContext);

  const INITIAL_VALUES: ILoginForm = {
    email: "",
    password: "",
  };

  const onSubmit: IFormikSubmit<ILoginForm> = ({ email, password }: ILoginForm, { setSubmitting }: FormikHelpers<ILoginForm>) => {
    setSubmitting(false);
    login(email, password);
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <div className="login-page container">
      <div className="row">
        <div className="col"></div>
        <div className="col-12 col-sm-12 col-md-6 login-container">
          <Formik
            initialValues={INITIAL_VALUES}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {(formProps) => (
              <Form>
                <div className="row">
                  <div className="col-12">
                    <h3><span className="mark-brand">P</span>ryvicy</h3>
                  </div>
                </div>
                <div className="row">
                  <Login {...formProps} />
                </div>
                <div className="row">
                  <div className="col-12">
                    {
                      !isLoadingToken ? (
                        <button type="submit" className="button-login">Submit</button>
                      ) : (
                          <>
                            <button type="submit" className="button-login">
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                              <span className="mr-2">Loading...</span>
                            </button>
                          </>
                        )
                    }
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className="col"></div>
      </div>
    </div>
  );
};