import { post, get } from "./request";
import { ICompaniesRequest, ICompaniesList } from "src/interfaces/ICompanies";
import { IFilterLikeCompanies, IFilterEqCompanies } from "src/interfaces/IFilterList";
import { IRequestListParams } from "../interfaces/IRequestListParams";
import { IResponseList } from "../interfaces/IResponseList";

const BASE: string = "license";

export const getLicenseList:
  FuncPromise<IRequestListParams<IFilterLikeCompanies, IFilterEqCompanies>> =
  (data: IRequestListParams<IFilterLikeCompanies, IFilterEqCompanies>):
    Promise<IResponseList<IFilterLikeCompanies, IFilterEqCompanies, ICompaniesList>> => (
      post(`${BASE}/query`, data)
    );

export const createLicense: FuncPromise<ICompaniesRequest, ICompaniesList> =
  (data: Partial<ICompaniesRequest>): Promise<ICompaniesList> => (
    post(`${BASE}`, data)
  );

export const getLicense: FuncPromise<string, ICompaniesList> =
  (id: string): Promise<ICompaniesList> => (
    get(`${BASE}/${id}`)
  );
