import React, { FC } from "react";
import { FormGroup } from "reactstrap";
import { FormGroupInputLabel } from "./FormGroupLabel";
import { FormGroupInputError } from "./FormGroupError";
import { FormGroupInputField } from "./FormGroupField";
import { FormikErrors, FormikTouched } from "formik";

interface IProps {
  id?: string;
  type: string;
  name: string;
  placeholder: string;
  errors: FormikErrors<any>;
  touched: FormikTouched<any>;
  label: string;
  markRequired?: boolean;
}

export const FormGroupInput: FC<IProps> = (props) => {
  const {
    type,
    name,
    placeholder = "Enter field",
    errors,
    touched,
    label,
    markRequired = false,
  } = props;

  return (
    <FormGroup>
      <FormGroupInputLabel
        name={name}
        label={label}
        markRequired={markRequired}
      />
      <FormGroupInputField
        type={type}
        name={name}
        placeholder={placeholder}
        errors={errors}
        touched={touched}
      />
      <FormGroupInputError
        errors={errors}
        touched={touched}
        name={name}
      />
    </FormGroup>
  );
};