
import { connectRouter } from "connected-react-router";
import { History } from "history";
import { combineReducers, Reducer } from "redux";
import { IStoreState } from "src/interfaces/IStoreState";

export default (history: History): Reducer<IStoreState, any> => (
  combineReducers({
    router: connectRouter(history),
  })
);
