import React, { FC, useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import { ID_DETECTION_STATUS } from "../../constants/IdDetectionStatus";
import { TRANSPORT_LABELS, SFTP } from "../../constants/attachmentsTransport";
import { ATTACHMENTS_ARCHIVING_MODE } from "../../constants/attachmentsArchivingMode";
import { ENCRYPTION_CHOICE } from "../../constants/encryptionChoice";
export const OperationsTd: FC<any> = ({ item }) => {
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const [popoverOpenStatus, setPopoverOpenStatus] = useState<boolean>(false);
  const { user } = useContext<any>(AppContext);

  // tslint:disable-next-line: typedef
  const toggle = () => setPopoverOpen(!popoverOpen);
  const toggleStatus = () => setPopoverOpenStatus(!popoverOpenStatus);

  return (
    <>
      <tr>
        <td className="minwidth-td">{item.from}</td>
        <td className="minwidth-td">{item.to}</td>
        <td className="minwidth-td">{item.cc.split(";").join("\n")}</td>
        <td>{item.bcc}</td>
        <td className="minwidth-td">{item.subject}</td>
        <td className={item.status === "Success" ? "success" : "failure"}>
          {item.status === "Failure" ? (
            <>
              <span id={`status-${item.id}`}> {item.status}</span>
              <Popover
                trigger="legacy"
                placement="bottom"
                isOpen={popoverOpenStatus}
                target={`status-${item.id}`}
                toggle={toggleStatus}
              >
                <PopoverHeader>Error message</PopoverHeader>
                <PopoverBody>
                  {
                    <div>
                      <span className="status-title">{item.errorMessage}</span>
                    </div>
                  }
                </PopoverBody>
              </Popover>
            </>
          ) : (
            <> {item.status}</>
          )}
        </td>
        <td>
          <span className="nowrap-td">
            {new Date(item.createdAt).toLocaleString()}
          </span>
        </td>
        <td
          className={
            item.attachmentsInfo?.attachments &&
            item.attachmentsInfo?.attachments.length > 0
              ? "attachment-link"
              : ""
          }
        >
          <div>
            {item.attachmentsInfo?.attachments &&
            item.attachmentsInfo?.attachments?.length > 0 ? (
              <>
                <span id={`attachment-${item.id}`}>
                  {item.attachmentsInfo.numberOfAttachments}
                </span>
                <Popover
                  trigger="legacy"
                  placement="bottom"
                  isOpen={popoverOpen}
                  target={`attachment-${item.id}`}
                  toggle={toggle}
                >
                  <PopoverHeader>Attachments</PopoverHeader>
                  <PopoverBody>
                    {item.attachmentsInfo.attachments.map(
                      (attach: any, index: number) => (
                        <div key={`${index}-${item.id}`}>
                          <span className="attach-title">
                            {attach.fileName}
                          </span>
                          {attach.compressedByClient && (
                            <span className="sub-title">Archived</span>
                          )}
                        </div>
                      )
                    )}
                    {item.attachmentsInfo.attachmentsDeliveryTransportType ==
                      SFTP && (
                      <div>
                        <a href={item.attachmentsInfo.sftpUrl} target="_blank">
                          SFTP URL
                        </a>
                      </div>
                    )}
                  </PopoverBody>
                </Popover>
              </>
            ) : (
              <>{item.attachmentsInfo?.numberOfAttachments}</>
            )}
          </div>
        </td>
        <td>
          {
            ATTACHMENTS_ARCHIVING_MODE[
              item.attachmentsInfo?.attachmentsArchivingMode
            ]
          }
        </td>
        <td>
          {TRANSPORT_LABELS[
            item.attachmentsInfo?.attachmentsDeliveryTransportType
          ] || TRANSPORT_LABELS.Outlook}
        </td>
        <td>
          {ID_DETECTION_STATUS[item.idNumberDetectionStatus] ||
            ID_DETECTION_STATUS.Skipped}
        </td>
        {user.role === "superadmin" ? (
          <td>
            <span className="nowrap-td">{item.tenantKey}</span>
          </td>
        ) : null}
        <td className="minwidth-td">{item.location}</td>
        {user.role === "superadmin" ? (
          <td className="minwidth-td">
            <span className="nowrap-td">{item.company}</span>
          </td>
        ) : null}
        <td className="minwidth-td">
          <span className="nowrap-td">{item.attachmentsInfo?.password}</span>
        </td>
        <td
          className={`minwidth-td nowrap-td ${
            item.emailEncryptionInfo?.lookupId ? "attachment-link" : ""
          }`}
        >
          <div>
            {item.emailEncryptionInfo?.lookupId ? (
              <>
                <span id={`encryptionInfo-${item.id}`}>
                  {item.emailEncryptionInfo.lookupId}
                </span>
                <Popover
                  trigger="legacy"
                  placement="bottom"
                  isOpen={popoverOpen}
                  target={`encryptionInfo-${item.id}`}
                  toggle={toggle}
                >
                  <PopoverHeader>Recipients</PopoverHeader>
                  <PopoverBody>
                    {item.emailEncryptionInfo.recipientsInfos.map(
                      (recipient: any, index: number) => (
                        <div key={`${index}-${item.emailAddress}`}>
                          <span className="attach-title">
                            {recipient.emailAddress}
                          </span>
                          {recipient.wasRequested && [
                            <span>
                              Decrypted at{" "}
                              {new Date(recipient.requestTime).toLocaleString()}
                            </span>,
                            recipient?.requestIp && (
                              <span> ({recipient.requestIp})</span>
                            ),
                          ]}
                        </div>
                      )
                    )}
                  </PopoverBody>
                </Popover>
              </>
            ) : (
              <></>
            )}
          </div>
        </td>
        <td className="minwidth-td">
          {item.attachmentsArchivingChoice !== ""
            ? ENCRYPTION_CHOICE[item.attachmentsArchivingChoice]
            : ""}
        </td>
        <td className="minwidth-td">
          {item.emailEncryptChoice !== ""
            ? ENCRYPTION_CHOICE[item.emailEncryptChoice]
            : ""}
        </td>
      </tr>
    </>
  );
};
