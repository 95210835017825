import React, { FC, useContext } from "react";
import { IFields } from "../../interfaces/IFields";
import { USER_INFO } from "../../constants/UserInfo";
import { AppContext } from "../../context/AppContext";
import { IHookAppReturn } from "../../hooks/interfaces/IHookAppState";
import { UserInfo } from "./UserInfo";

export const UserInfoDetails: FC = () => {

  const excludeItems: string[] = ["id"];

  const { user }: any = useContext<IHookAppReturn>(AppContext);

  const filterFields: FuncWithoutParams<IFields[]> = (): IFields[] => {
    return Object.keys(user || {}).filter((item: string) => {
      return !excludeItems.includes(item);
    }).map((item: string) => {
      return {
        title: USER_INFO[item],
        value: user[item]
      };
    });
  };

  return (
    <>
      {user && <UserInfo userInfo={filterFields()}/>}
    </>
  );
};