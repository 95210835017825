import React, { FC } from "react";
import Tabs from "../../components/Tabs";
import AddUser from "../../components/AddUser";
import { useUpsertUser } from "../../hooks/useUpsertUser";

export const AddUserPage: FC = () => {
  const { isLoading, upsertUser, error, submitting, complete }: any = useUpsertUser();

  return (
    <div className="add-user-container">
      <h2 className="header-title">
        <span> <span className="mark-brand">N</span>ew User Registration</span>
        <span className="underline"></span>
      </h2>
      <Tabs>
        {(activeTab: string, toggle: any) => (
          <>
            <AddUser
              complete={complete}
              submitting={submitting}
              activeTab={activeTab}
              toggleTab={toggle}
              upsert={upsertUser}
              error={error} />
          </>
        )}
      </Tabs>
    </div>
  );
};