import React, { FC } from "react";
import UserLayout from "../../components/UserInfo";
import { UserInfoDetails } from "../../components/UserInfo/UserInfoDetails";
import { UserInfoEdit } from "../../components/UserInfo/UserInfoEdit";
import { useRouteMatch, Switch, Redirect, match } from "react-router-dom";
import { PrivateRoute } from "../../components/PrivateRoute";

export const UserInfoPage: FC = () => {

  const { path }: match = useRouteMatch();

  return (
    <>
      <Switch>
        <Redirect exact={true} to={`${path}/details`} from={path} />
        <UserLayout>
          <PrivateRoute exact={true} path={`${path}/details`}>
            <UserInfoDetails/>
        </PrivateRoute>
          <PrivateRoute exact={true} path={`${path}/edit`}>
            <UserInfoEdit/>
        </PrivateRoute>
        </UserLayout>
      </Switch>
    </>
  );
};