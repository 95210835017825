import React, { FC } from "react";
import { FormGroup, Label } from "reactstrap";
import { Field } from "formik";
import FormGroupInput from "../UI/FormGroupInput";
export const AddUserForm: FC<any> = (props) => {

  const {
    errors,
    touched,
    editable,
  } = props;

  return (
    <>
      <div className="row">
        <div className="col-5">
          <FormGroupInput
            type="text"
            id="name"
            name="name"
            label="Name"
            markRequired={true}
            placeholder="Enter name"
            errors={errors}
            touched={touched}
          />
        </div>
        <div className="col-5">
          <FormGroupInput
            type="text"
            id="surname"
            name="surname"
            label="Surname"
            placeholder="Enter surname"
            errors={errors}
            touched={touched}
          />
        </div>
        <div className="col-2">
          <FormGroup>
            <Label for="gender">Gender</Label>
            <Field as="select" name="gender" className="form-control">
              <option value="male">Male</option>
              <option value="female">Female</option>
            </Field>
          </FormGroup>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <FormGroupInput
            type="text"
            id="company"
            name="company"
            label="Company name"
            markRequired={true}
            placeholder="Enter company"
            errors={errors}
            touched={touched}
          />
        </div>
        <div className="col-6">
          <FormGroupInput
            type="text"
            id="occupation"
            name="occupation"
            label="Occupation"
            placeholder="Enter occupation"
            errors={errors}
            touched={touched}
          />
        </div>
      </div>
      <div className="row">
        {
          !editable && (
            <div className="col-6">
              <FormGroupInput
                type="text"
                id="email"
                name="email"
                label="Email"
                markRequired={true}
                placeholder="Enter email"
                errors={errors}
                touched={touched}
              />
            </div>
          )
        }
        <div className="col-6">
          <FormGroupInput
            type="text"
            id="mobile"
            name="mobile"
            label="Mobile phone"
            placeholder="Enter mobile phone"
            errors={errors}
            touched={touched}
          />
        </div>
      </div>
      <div className="row">
      <div className="col-6">
          <FormGroupInput
            type="text"
            id="website"
            name="website"
            label="Website"
            placeholder="Enter website"
            errors={errors}
            touched={touched}
          />
        </div>
        <div className="col-6">
          <FormGroupInput
            type="text"
            id="position"
            name="position"
            label="Position"
            placeholder="Enter position"
            errors={errors}
            touched={touched}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <FormGroupInput
            type="text"
            id="country"
            name="country"
            label="Country"
            placeholder="Enter country"
            errors={errors}
            touched={touched}
          />
        </div>
        <div className="col-4">
          <FormGroupInput
            type="text"
            id="address"
            name="address"
            label="Address"
            placeholder="Enter address"
            errors={errors}
            touched={touched}
          />
        </div>
        <div className="col-4">
          <FormGroupInput
            type="text"
            id="location"
            name="location"
            label="Location"
            placeholder="Enter location"
            errors={errors}
            touched={touched}
          />
        </div>
      </div>
      {
        !editable && (
          <div className="row">
            <div className="col-6">
              <FormGroupInput
                type="password"
                id="password"
                name="password"
                label="Password"
                markRequired={true}
                placeholder="Enter password"
                errors={errors}
                touched={touched}
              />
            </div>
            <div className="col-6">
              <FormGroupInput
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                label="Confirm password"
                markRequired={true}
                placeholder="Confirm password"
                errors={errors}
                touched={touched}
              />
            </div>
          </div>
        )
      }
    </>
  );
};