import * as yup from "yup";

export const validationSchema: yup.ObjectSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  company: yup.string().required("Company is required"),
  email: yup.string().required("Email is required").email("Email must be a valid format"),
  password: yup.string().required("Password is required"),
  confirmPassword: yup.string().required("Confirm password is required")
     .oneOf([yup.ref("password"), null], "Passwords must match"),
  bccEmailField: yup.string().email("Email must be a valid format"),
});