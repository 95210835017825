import { useReducer, useCallback, useEffect, Reducer } from "react";
import { reducer } from "./reducers/useTableListReducer";
import { IHookTable, IUseTableListReturn, IRequestListParams } from "./interfaces/IHookTable";
import { AnyAction } from "redux";
import {
  getListRequest,
  getListRequestDone,
  getListRequestFailed,
  setFiltersRequest,
  setDirectionRequest,
  setPaginationRequest,
  setSizePerPageRequest,
  setSortRequest,
  updateStatus,
} from "./actions/useTableListActions";
import { createLicense } from "../api";

function buildParams(params: any): Partial<IRequestListParams> {
  const filters: object = params.filters;
  return {
    page: params.page,
    pageSize: params.pageSize,
    sort: params.sort,
    direction: params.direction,
    ...filters,
  };
}

// tslint:disable-next-line: typedef
function normalizeList(list: any[], key: string) {
  let data: any = {};

  list.map((item: any) => {
    return data[item[key]] = item;
  });

  return data;
}
// tslint:disable-next-line: typedef
function changeDirection(direction: string) {
  return direction === "asc" ? "desc" : "asc";
}

// tslint:disable-next-line: typedef
export const useTableList =
  <T extends object = object, F extends object = object>(findMethod: FuncPromise,
    initialList: IHookTable<T, F>, flatKey: string): IUseTableListReturn<T, F> => {

    const [state, dispatch] = useReducer<Reducer<IHookTable<T, F>, AnyAction>>(reducer, initialList as IHookTable<T, F>);

    const {
      list,
      filters,
      pagination,
      sort,
      flat,
      direction,
      count,
      isLoadingList,
    } = state;

    const findList: FuncVoidWithoutParams = useCallback(async () => {
      dispatch(getListRequest());
      try {
        const result: any = await findMethod(buildParams({ ...pagination, sort, direction, filters }));
        // tslint:disable-next-line: typedef
        const flatEntities = normalizeList(result.data, flatKey);
        dispatch(getListRequestDone({...result, flatData: flatEntities}));
      } catch (e) {
        dispatch(getListRequestFailed(e));
      }
    }, [findMethod, pagination, sort, direction, filters, flatKey]);

    const setFilters: FuncVoid = useCallback((filters: F): void => {
      dispatch(setFiltersRequest(filters));
    }, []);

    const setSort: FuncVoid = useCallback((sortValue: string): void => {
      dispatch(setSortRequest({
        sort: sortValue,
        direction: sortValue === sort ? changeDirection(direction) : "asc",
      }));
    }, [direction, sort]);

    const setPagination: FuncVoid = useCallback((page: number): void => {
      dispatch(setPaginationRequest(page));
    }, []);

    const setDirection: FuncVoid = useCallback((direction: string): void => {
      dispatch(setDirectionRequest(direction));
    }, []);

    const setSizePerPage: FuncVoid = useCallback((pageSize: number): void => {
      dispatch(setSizePerPageRequest(pageSize));
    }, []);

    const updateStatusCompanies: any = async (tenantKey: string, isEnabled: string) => {
      const result: any = await createLicense({
        tenantKey,
        isEnabled,
      });
      dispatch(updateStatus(result));
    };

    useEffect(() => {
      findList();
    }, [findList]);


    return {
      list,
      flat,
      count,
      filters,
      isLoadingList,
      findList,
      pagination,
      sort,
      direction,
      setFilters,
      setDirection,
      setSizePerPage,
      setPagination,
      setSort,
      updateStatusCompanies,
    };
  };