export const USER_INFO: {[key: string]: string} = {
  location: "Location",
  gender: "Gender",
  occupation: "Occupation",
  country: "Country",
  address: "Address",
  website: "Website",
  mobile: "Mobile",
  position: "Position",
  company: "Company",
  tenantKey: "Tenant key",
  surname: "Surname",
  name: "Name",
  email: "Email",
  createdAt: "Created",
  role: "Role"
};