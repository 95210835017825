import React, { FC, useContext } from "react";
import { OperationsTd } from "./OperationsTd";
import { TableList } from "../Table";
import { AppContext } from "../../context/AppContext";
import { Sorting } from "../Sorting";
import { FormGroup, Input } from "reactstrap";

export const OperationsTable: FC<any> = (props) => {
  const {
    list,
    page,
    pageSize,
    pagination,
    setSort,
    direction,
    sort,
    count,
    setSizePerPage,
  } = props;

  const { user } = useContext(AppContext);

  return (
    <>
      <TableList
        setSizePerPage={setSizePerPage}
        typeHead="operations"
        page={page}
        pageSize={pageSize}
        pagination={pagination}
        count={count}
      >
        <thead>
          <tr>
            <Sorting
              name="from"
              setSort={setSort}
              direction={direction}
              sort={sort}
              classTh="text-overflow-th small-th-width"
            >
              From
            </Sorting>
            <th className="text-overflow-th small-th-width">To</th>
            <th className="text-overflow-th small-th-width">CC</th>
            <th className="text-overflow-th small-th-width">BCC</th>
            <th className="text-overflow-th small-th-width">Subject</th>
            <th className="text-overflow-th small-th-width">Status</th>
            <Sorting
              name="createdAt"
              setSort={setSort}
              direction={direction}
              sort={sort}
              classTh="text-overflow-th medium-th-width"
            >
              Created at
            </Sorting>
            <th>
              <div>Number of attachments</div>
            </th>
            <th>Attachments archiving mode</th>
            <th>Attachments delivery transport</th>
            <th>ID detection</th>
            {user.role === "superadmin" ? (
              <Sorting
                name="tenantKey"
                setSort={setSort}
                direction={direction}
                sort={sort}
              >
                Tenant key
              </Sorting>
            ) : null}
            <Sorting
              name="location"
              setSort={setSort}
              direction={direction}
              sort={sort}
            >
              Location
            </Sorting>
            {user.role === "superadmin" ? <th>Company</th> : null}
            <th>Password</th>
            <th>Email encryption info</th>
            <th>Archiving choice</th>
            <th>Email encryption choice</th>
          </tr>
        </thead>
        <tbody>
          {list.length > 0 ? (
            list.map((elem: any, index: number) => (
              <OperationsTd key={index} item={elem} />
            ))
          ) : (
            <tr>
              <td
                className="empty-row"
                colSpan={user.role === "superadmin" ? 13 : 11}
              >
                Data not found
              </td>
            </tr>
          )}
        </tbody>
      </TableList>
    </>
  );
};
