import React, { FC } from "react";
import { Label } from "reactstrap";

interface IProps {
  name: string;
  label: string;
  markRequired: boolean;
}
export const FormGroupInputLabel: FC<IProps> = (props) => {
  const {
    name,
    label = "Label",
    markRequired = false,
  } = props;

  return (
    <Label for={name}>
      {label}
      {markRequired && <span className="mark-required">*</span>}
    </Label>
  );
};